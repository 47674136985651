import React, { useState } from "react";
import { motion } from "framer-motion";

import "./HomeSection1.scss";

const HomeSection1 = () => {
    const [bgEnterAnimation, setBgEnterAnimation] = useState(false);

    return (
        <section className="section section1">
            <motion.div
                viewport={{ once: false, amount: .01 }}
                onViewportEnter={() => setBgEnterAnimation(true)}
                onViewportLeave={() => setBgEnterAnimation(false)}
                className={`section1__background2 ${bgEnterAnimation ? 'section1__background2--enter' : ''}`}
            />

            <div className="section1__background3"/>

            <h1>Весь спектр<br />телеком-услуг</h1>

            <span className="section1__bottomborder"/>
        </section >
    );
};

export default HomeSection1;
