//
import React from "react";
import "./VacancySection1.scss";

import FirstScreen from "../../../../components/FirstScreen/FirstScreen.jsx";

import { motion } from "framer-motion";

const VacancySection1 = () => {

    const opacityScale110 = {
        hidden: {
            // scale: 1.1,
            x: -30,
            opacity: 0,
        },

        visible: {
            // scale: 1,
            x: 0,
            opacity: 1,
            transition: {
                duration: 3,
                // delay: 0.2,
                ease: 'easeOut',
            },
        },
    }

    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: true }}

            className="vacancy1"
        >
                <motion.div
                    variants={opacityScale110}
                    className="vacancy1__BG1"
                ></motion.div>

            <FirstScreen
                activeHeaderLink='vacancy'
                textDescription='Вакансии'
                textLine1='Карьерный рост в команде'
                textLine2='профессионалов'
            />
        </motion.section>
    )
}

export default VacancySection1;

