import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import "./ProjectsSection2.scss";

import { projects } from "../../../../data/projects.js";

import arrow from "../../../../images/arrow.svg";
import { useEffect } from "react";

const variants = {
    heading: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: .75,
                ease: 'anticipate',
            },
        },
    },

    projectTabs: {
        hidden: { x: 20, opacity: 0 },
        visible: custom => ({
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.6,
                ease: 'circOut',
                delay: .25 + 0.04 * custom,
            },
        }),
    },

    arrowButtons: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: .75,
                ease: 'anticipate',
                delay: .5,
            },
        },
    },

    tabContentAdditionalDelay: .5,
    tabContent: {
        hidden: { x: 20, opacity: 0 },
        visible: custom => ({
            x: 0,
            opacity: 1,
            transition: {
                duration: .75,
                ease: 'circOut',
                delay: variants.tabContentAdditionalDelay + 0.04 * custom,
            },
        }),
    },
};

const ProjectsSection2 = () => {
    const [activeElement, setActiveElement] = useState(0);

    useEffect(() => {
        let el0 = document.querySelector('.projects2__links');
        let el2 = document.querySelector('.projects2__links_active');

        el0.scrollTo({
            left: el2.offsetLeft,
            behavior: 'smooth'
          })

    }, [activeElement]);

    const nextProject = (way) => {
        let numberEl = activeElement;

        if (way === 'left') {
            if (numberEl === 0) {
                numberEl = projects.length - 1;
            } else {
                numberEl--;
            }
        }

        if (way === 'right') {
            if (numberEl === projects.length - 1) {
                numberEl = 0;
            } else {
                numberEl++;
            }
        }

        setActiveElement(numberEl);
    };

    return (
        <motion.section
            className="projects2"
            id="nextpage"
            onAnimationComplete={() => {
                variants.tabContentAdditionalDelay = 0;
            }}
        >
            <div id="projects" className="projects2__page">
                <div className="projects2__heading">
                    <h2>Проекты ФИЛАНКО</h2>
                </div>

                <div className="projects2__links">
                    <div className="projects2__links_inline">
                        {projects.map((el, index) =>
                            <span
                                key={index}
                                onClick={() => setActiveElement(index)}
                                className={index === activeElement ? "projects2__links_active" : "projects2__links_passive"}
                            >
                                {el.name}
                            </span>
                        )}
                    </div>
                </div>

                <div className="projects2__button1">
                    <div className="projects2__button1_left" onClick={() => nextProject('left')}>
                        <img src={arrow} alt="" />
                    </div>
                    <div className="projects2__button1_right" onClick={() => nextProject('right')}>
                        <img src={arrow} alt="" />
                    </div>
                </div>

                <AnimatePresence exitBeforeEnter>
                    <div key={activeElement} >
                        {projects.map((project, i) => activeElement === i && (
                            <motion.div
                                key={i}

                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0, transition: { duration: .2 } }}
                            >
                                <div className="projects2__project">
                                    <motion.div variants={variants.tabContent} custom={0} className="projects2__icon">
                                        <a href={project.url} target="_blank" rel="noreferrer">
                                            <img src={project.logo} alt={project.name} />
                                        </a>
                                    </motion.div>
                                    <motion.div variants={variants.tabContent} custom={1} className="projects2__slogan">
                                        <p>{project.slogan}</p>
                                    </motion.div>
                                </div>

                                <motion.div className="projects2__content">
                                    <motion.div variants={variants.tabContent} custom={2} className="projects2__text">
                                        <p>{project.content}</p>
                                    </motion.div>

                                    <div className="projects2__services">
                                        <motion.div variants={variants.tabContent} custom={3} className="projects2__servicesheading">
                                            <h3>Услуги</h3>
                                        </motion.div>
                                        <motion.div className="projects2__servicesul" variants={variants.tabContent} custom={4}>
                                            <ul>
                                                {project.services.map((el, index) => <li key={index}>{el}</li>)}
                                            </ul>
                                        </motion.div>
                                    </div>
                                </motion.div>

                                <motion.div variants={variants.tabContent} custom={5} className="projects2__button2">
                                    <a href={project.url} target="_blank" rel="noreferrer">
                                        <img src={arrow} alt="arrow" />
                                        <span> Перейти</span>
                                    </a>
                                </motion.div>
                            </motion.div>
                        ))}
                    </div>
                </AnimatePresence>

            </div>
        </motion.section>
    )
}
export default ProjectsSection2;
