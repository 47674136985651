//
import React from "react";
import "./ContactsSection1.scss";

import FirstScreen from "../../../../components/FirstScreen/FirstScreen.jsx";

import { motion } from "framer-motion";

const ContactsSection1 = () => {

    const opacityScale110 = {
        hidden: {
            // scale: 1.1,
            x: -30,
            opacity: 0,
        },

        visible: {
            // scale: 1,
            x: 0,
            opacity: 1,
            transition: {
                duration: 3,
                // delay: 0.2,
                ease: 'easeOut',
            },
        },
    }

    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: true }}

            className="contacts1"
        >
            <motion.div
                variants={opacityScale110}
                className="contacts1__BG1"
            ></motion.div>
            <FirstScreen
                activeHeaderLink='contacts'
                textDescription='контакты'
                textLine1='Мы всегда открыты'
                textLine2='для общения'
            />
        </motion.section>
    )
}

export default ContactsSection1;