import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

import logoFilancoWhite from "../../images/logoFilancoWhite.svg";

import { motion } from "framer-motion";

const opacity = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.3,
            ease: 'easeOut',
            // delay: 0.1,
        },
    },
}

const CURRENT_YEAR = new Date().getFullYear();

const Footer = () => {
    return (
        <motion.footer
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.4, once: true }}
            className="footer"
        >
            <motion.div
                variants={opacity}
                className="footer__page"
            >
                <div className="footer__block1">
                    <div className="footer__logo">
                        <Link to="/">
                            <img src={logoFilancoWhite} alt="" />
                        </Link>
                    </div>
                    <div className="footer__links">
                        <div className="footer__links1">
                            <h3>О Компании</h3>
                            <Link to="/company">Компания</Link>
                            <Link to="/projects">Проекты</Link>
                            <Link to="/team">Команда</Link>
                        </div>
                        <div className="footer__links3">
                            <h3>Информация</h3>
                            <Link to="/partners">Партнерам</Link>
                            <Link to="/documents">Документы</Link>
                            <Link to="/recommendations">Рекомендации</Link>
                        </div>
                        <div className="footer__links3">
                            <h3>Свяжитесь с нами</h3>
                            <Link to="/contacts">Контакты</Link>
                            <Link to="/vacancy">Вакансии</Link>
                        </div>
                    </div>
                    <div className="footer__phones">
                        <div className="footer__phone">
                            <a href="tel:+78002000542">8 800 200-05-42</a>
                            <span>для звонков из Москвы</span>
                        </div>
                        <div className="footer__phone">
                            <a href="tel:+74953630542">8 (495) 363-05-42</a>
                            <span>для звонков из Москвы</span>
                        </div>
                        <div className="footer__mail">
                            <a href="mailto:info@filanco.ru">info@filanco.ru</a>
                        </div>
                    </div>
                </div>

                <div className="footer__block2">
                    <div className="footer__copyright">
                        <span>© 1999-{CURRENT_YEAR} ГК Филанко. Все права защищены</span>
                    </div>
                    <div className="footer__social">
                        {/* <span>socialIcons</span> */}
                    </div>
                    <div className="footer__agreement">
                        <Link to="/privacy" target="_blank">Политика конфиденциальности</Link>
                    </div>
                </div>
            </motion.div>
        </motion.footer>
    )
}

export default Footer;
