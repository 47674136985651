//ссылка на внешний документ
import React from "react";
import styles from "./LinkArrowRightOut.module.scss";
import arrow from "./arrow.svg"

const LinkArrowRightOut = ({ link = '/', alt = '', children = 'text' }) => {
    return (
        <a href={link} target="_blank" className={styles.link}  rel="noreferrer">
            <img src={arrow} alt={alt} />
            <span>{children}</span>
        </a>
    )
}

export default LinkArrowRightOut;