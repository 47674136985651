import React, { useState } from "react";
import { Helmet } from "react-helmet";

import "./Home.scss";

import HomeSection1 from "./components/HomeSection1/HomeSection1";
import HomeSection2 from "./components/HomeSection2/HomeSection2";
import HomeSection3 from "./components/HomeSection3/HomeSection3";
import HomeSection4 from "./components/HomeSection4/HomeSection4";
import HomeSection5 from "./components/HomeSection5/HomeSection5";
import HomeSection6 from "./components/HomeSection6/HomeSection6";
import HomeSection7 from "./components/HomeSection7/HomeSection7";

import Description from "../../UI/Description/Description.jsx";
import Header from "../../components/Header/Header.jsx";
import Control from "../../UI/Control/Control.jsx";
import UpArrow from "../../UI/UpArrow/UpArrow.jsx";

const Home = () => {
    const [pageNumber, setPageNumber] = useState(0);
    const [moveY, setMoveY] = useState(0);
    const [moveX, setMoveX] = useState(0);
    const [wheelDelayTimerId, setWheelDelayTimerId] = useState(-1);

    const wheel = (event) => {
        if (wheelDelayTimerId !== -1) {
            return;
        }
        setWheelDelayTimerId(
            setTimeout(() => setWheelDelayTimerId(-1), 500)
        );

        let nextPage = pageNumber;

        if (event.deltaY > 0 && nextPage < 6) { //down
            nextPage++;
        }
        else if (event.deltaY < 0 && nextPage > 0) { //up
            nextPage--;
        }

        if (nextPage === pageNumber) {      //НЕ сменилась страница
            return;
        }

        setPageNumber(nextPage);
    }

    const touchStart = (event) => {
        setMoveY(event.changedTouches[0].clientY);
        setMoveX(event.changedTouches[0].clientX);
    }
    const touchEnd = (event) => {
        let mvY = moveY - event.changedTouches[0].clientY;
        let mvX = moveX - event.changedTouches[0].clientX;

        if (Math.abs(mvX * 2) > Math.abs(mvY)){ //убираю диагональный скрол
            return;
        }

        let nextPage = pageNumber;

        if (mvY > 0 && nextPage < 6) { //down
            nextPage++;
        }
        else if (mvY < 0 && nextPage > 0) { //up
            nextPage--;
        }

        if (nextPage === pageNumber) {      //НЕ сменилась страница
            return;
        }

        setPageNumber(nextPage);
    }

    const pageTitles = [
        '',
        'о компании',
        'услуги',
        'проекты',
        'опыт',
        'клиенты',
        'цель',
    ];

    return (
        <div className="page" onWheel={wheel} onTouchEnd={touchEnd} onTouchStart={touchStart}>
            <Helmet>
                <title>ФИЛАНКО интернет провайдер и оператор связи для юридических лиц — ИТ интегратор цифровых решений в России</title>
                <meta name="description" content="Интернет провайдер и оператор связи для юридических лиц. Перечень услуг охватывает все телеком-направления — от виртуального хостинга и услуг дата-центров, до организации слаботочных сетей и доступа в Интернет." />
            </Helmet>

            <Header whiteLogo={pageNumber !== 1} links={pageNumber === 0} />

            {pageTitles.map((title, index) =>
                <Description key={index} text={title} active={index === pageNumber} />
            )}

            <Control setPageNumber={setPageNumber} pageNumber={pageNumber} pagesTotal={7} />

            <UpArrow onClick={() => setPageNumber(pageNumber === 0 ? 1 : 0)} isStartPage={pageNumber === 0} />

            <div className="page__container" style={{ transform: `translateY(${-window.innerHeight * pageNumber}px)` }}>
                <HomeSection1 />
                <HomeSection2 />
                <HomeSection3 />
                <HomeSection4 />
                <HomeSection5 />
                <HomeSection7 />
                <HomeSection6 />
            </div>
        </div>
    );
};

export default Home;
