import React, { useEffect, useMemo, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import "./HomeSection3.scss";

import { services } from "../../../../data/homeServices";

const opacityX10 = {
    initial: {
        x: 20,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.75,
            ease: 'circOut',
            once: false,
        },
    },

    exit: {
        opacity: 0,
        transition: {
            duration: 0.4,
            delay: 0.1,
        },
    }
};

const catBtn = [
    {
        filter: '1',
        btnTextBig: 1,
        btnText: <>Услуги<br />для бизнеса</>,
    },
    {
        filter: '2',
        btnTextBig: 2,
        btnText: <>Владельцам<br />недвижимости</>,
    },
    {
        filter: '3',
        btnTextBig: 3,
        btnText: <>Управляющим<br />компаниям</>,
    },
    {
        filter: '4',
        btnTextBig: 4,
        btnText: <>Предложения<br />застройщикам</>,
    },
    {
        filter: '5',
        btnTextBig: 5,
        btnText: <>Операторам<br />связи</>,
    },
];

function animateBg(refs, tabIndex, inView) {
    refs.forEach((ref, i) => {
        if (!inView) {
            return ref.current.classList.remove('section3__BG1--enter', 'section3__BG1--leave');
        }

        if (tabIndex === (i + 1).toString()) {
            ref.current.classList.remove('section3__BG1--leave');
            void ref.current.clientWidth; // forcing reflow
            ref.current.classList.add('section3__BG1--enter');
        } else {
            ref.current.classList.remove('section3__BG1--enter');
            ref.current.classList.add('section3__BG1--leave');
        }
    });
}

const HomeSection3 = () => {
    const [filterCategory, setFilterCategory] = useState('1');  //категория//подсветка категории
    const [liActive, setLiActive] = useState('');   //подсветка элемента списка
    const [moveY, setMoveY] = useState(0);
    const [moveX, setMoveX] = useState(0);
    const [prevTextScrollTop, setPrevTextScrollTop] = useState(0);
    const [bgInView, setBgInView] = useState(false);
    const [bgRefs] = useState([useRef(), useRef(), useRef()]);

    let arrResult = useMemo(() => {
        return services.filter((element) => filterCategory === element.category);
    }, [filterCategory]);

    useEffect(() => {
        setLiActive(arrResult[0].title);   //подсветка элемента списка
    }, [arrResult]);

    const touchStart = (event) => {
        setMoveY(event.changedTouches[0].clientY);
        setMoveX(event.changedTouches[0].clientX);

        const el = event.target.closest('.section3__quotetext');
        setPrevTextScrollTop(el.scrollTop);
    }
    const touchEnd = (event) => {
        const el = event.target.closest('.section3__quotetext');

        const scrollGap = el.scrollHeight - el.offsetHeight;
        if (
            scrollGap && (
                (prevTextScrollTop > 0 && el.scrollTop === 0)
                || (prevTextScrollTop < scrollGap && el.scrollTop >= scrollGap)
                || (el.scrollTop > 0 && el.scrollTop < scrollGap)
            )
        ) {
            event.stopPropagation();
        }

        let mvY = moveY - event.changedTouches[0].clientY;
        let mvX = moveX - event.changedTouches[0].clientX;

        if (Math.abs(mvY * 2) > Math.abs(mvX)) { //убираю диагональный скрол, остается горизонтальный
            return;
        }

        if (mvX > 0) {
            //в
            let i = 1;
            while (i < 6) {
                if (filterCategory === i.toString()) {
                    setFilterCategory((i++).toString())
                }
            }
        }
        if (mvX < 0) {
            //вправо
            let i = 5;
            while (i > 0) {
                if (filterCategory === i.toString()) {
                    setFilterCategory((i--).toString())
                }
            }
        }
    }

    useEffect(() => {
        let el1 = document.querySelector('.section3__buttonwrapper');
        let el2 = document.querySelector('.section3__button_active');

        el1.scrollTo({
            left: el2.offsetLeft,
            behavior: 'smooth'
        })

    }, [filterCategory]);

    useEffect(() => {
        animateBg(bgRefs, filterCategory, bgInView);
    }, [bgRefs, filterCategory, bgInView]);

    return (
        <section className="section section3">
            <motion.div
                viewport={{ once: false, amount: .01 }}
                onViewportEnter={() => setBgInView(true)}
                onViewportLeave={() => setBgInView(false)}
                className="section3__BG1-wrap"
            >
                <div ref={bgRefs[0]} className="section3__BG1 section3__BG1--1"/>
                <div ref={bgRefs[1]} className="section3__BG1 section3__BG1--2"/>
                <div ref={bgRefs[2]} className="section3__BG1 section3__BG1--3"/>
            </motion.div>

            <div className="section3__BG2"/>

            <div className="section3__content">

                <div className="section3__buttonwrapper">
                    {catBtn.map((el, index) =>
                        <button
                            key={index}
                            className={`section3__button ${filterCategory === el.filter ? 'section3__button_active' : 'section3__button_passive'}`}
                            onClick={() => setFilterCategory(el.filter)}
                        >
                            <span className="section3__buttontext_big">{el.btnTextBig}</span>
                            <span className="section3__buttontext">{el.btnText}</span>
                        </button>
                    )}
                </div>

                <div className="section3__textwrapper">

                    <ul className="section3__ul">
                        <AnimatePresence exitBeforeEnter>
                            {services.map((element, index) =>
                                element.category === filterCategory &&
                                <motion.li
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { duration: .75, ease: 'circOut', } }}
                                    exit={{ opacity: 0, transition: { duration: .4, delay: 0.1 } }}
                                    className={liActive === element.title ? "section3__ul_active" : "section3__ul_passive"}
                                    onClick={() => setLiActive(element.title)}
                                >{element.title}</motion.li>
                            )}
                        </AnimatePresence>
                    </ul>

                    <div className="section3__quote">
                        <div className="section3__quotetext"
                            onTouchEnd={touchEnd}
                            onTouchStart={touchStart}
                        >

                            <AnimatePresence exitBeforeEnter>
                                {services.map((el, index) =>
                                    el.title === liActive &&
                                    <motion.p
                                        key={index}
                                        initial={'initial'}
                                        animate={'animate'}
                                        exit={'exit'}
                                        variants={opacityX10}
                                    >
                                        {el.content}
                                    </motion.p>
                                )}
                            </AnimatePresence>

                        </div>
                        <div className="section3__quotelogo">
                            <AnimatePresence exitBeforeEnter>
                                {services.map((el, index) =>
                                    el.title === liActive &&
                                    <a key={index} href={el.url} target="_blank" rel="noreferrer">
                                        <motion.img
                                            initial={'initial'}
                                            animate={'animate'}
                                            exit={'exit'}
                                            variants={opacityX10}
                                            src={el.logo}
                                            alt="logo"
                                        />
                                    </a>
                                )}
                            </AnimatePresence>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default HomeSection3;
