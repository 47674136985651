import React from "react";
import { Helmet } from "react-helmet";

import "./Notfound.scss";

import Footer from "../../components/Footer/Footer.jsx"

const Notfound = () => {
    return (
        <div className="notfount">
            <Helmet>
                <title>Страница не найдена. 404</title>
                <meta name="description" content="" />
            </Helmet>
            <div className="notfount__page">
                <h1>Страница не найдена</h1>
            </div>
            <Footer />
        </div>
    )
}

export default Notfound;
