import React from "react";
import "./ContactsSection3.scss";

import { YMaps } from "@pbe/react-yandex-maps";
import YandexMap from "../../../../components/YandexMap/YandexMap";

const ContactsSection3 = () => {
    return (
        <section className="contacts3">
            <YMaps>
                <div className="contacts3__page">
                    <div className="contacts3__office" >
                        <div className="contact3__heading">
                            <h2 >Главный офис</h2>
                        </div>

                        <div className="contact3__addresswrapper">
                            <div className="contacts3__addressarr">
                                <div className="contacts3__address" >
                                    <h3>Москва</h3>
                                    <div className="contacts3__address2">
                                        <address className="contacts3__street">115230, Электролитный проезд, д. 3, стр. 47</address>
                                        <div className="contacts3__phone"><a href="tel:+74953630542">8 (495) 363-05-42</a></div>
                                        <div className="contacts3__mail"><a href="mailto:info@filanco.ru">info@filanco.ru</a></div>
                                    </div>
                                </div>
                                <div className="contacts3__address" >
                                    <h3>Дата-центр «Медная фольга»</h3>
                                    <div className="contacts3__address2">
                                        <address className="contacts3__street">115230, Электролитный проезд, д. 3, стр. 1</address>
                                        <div className="contacts3__phone"><a href="tel:+74955454400">8 (495) 545-44-00</a></div>
                                        <div className="contacts3__mail"><a href="mailto:support@datahouse.ru">support@datahouse.ru</a></div>
                                    </div>
                                </div>

                                <div className="contacts3__map">
                                    <YandexMap coordinates={[55.674549, 37.615632]} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="contacts3__office" >
                        <h2 >Филиалы</h2>
                        <div>
                            <div className="contacts3__addressarr">
                                <div className="contacts3__address">
                                    <h3>Санкт-Петербург</h3>
                                    <div className="contacts3__address2">
                                        <address className="contacts3__street">190013, Введенский канал, д. 7, БЦ «Фарватер»</address>
                                        <div className="contacts3__phone"><a href="tel:+78126779111">8 (812) 677-91-11</a></div>
                                        <div className="contacts3__mail"><a href="mailto:ao@citytelecom.ru">ao@citytelecom.ru</a></div>
                                    </div>
                                </div>
                                <div className="contacts3__address">
                                    <h3>Дата-центр на Лифляндской</h3>
                                    <div className="contacts3__address2">
                                        <address className="contacts3__street">190020, Лифляндская ул. 3</address>
                                        <div className="contacts3__phone"><a href="tel:+78126779109">8 (812) 677-91-09</a></div>
                                        <div className="contacts3__mail"><a href="mailto:ao@citytelecom.ru">ao@citytelecom.ru</a></div>
                                    </div>
                                </div>

                                <div className="contacts3__map">
                                    <YandexMap coordinates={[59.907275, 30.262553]} />
                                </div>

                            </div>

                            <div className="contacts3__addressarr">
                                <div className="contacts3__address">
                                    <h3>Екатеринбург</h3>
                                    <div className="contacts3__address2">
                                        <address className="contacts3__street">620100, ул. Сибирский Тракт, д. 8</address>
                                        <div className="contacts3__phone"><a href="tel:+73433119081">8 (343) 311-90-81</a></div>
                                        <div className="contacts3__mail"><a href="mailto:sales@datahouse.ru">sales@datahouse.ru</a></div>
                                    </div>
                                </div>
                                <div className="contacts3__address">
                                    <h3>Дата-центр Екатеринбург</h3>
                                    <div className="contacts3__address2">
                                        <address className="contacts3__street">620100, ул. Сибирский Тракт, д. 8В</address>
                                        <div className="contacts3__phone"><a href="tel:+73433119081">8 (343) 311-90-81</a></div>
                                        <div className="contacts3__mail"><a href="mailto:sales@datahouse.ru">sales@datahouse.ru</a></div>
                                    </div>
                                </div>

                                <div className="contacts3__map">
                                    <YandexMap coordinates={[56.825217, 60.643091]} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </YMaps>
        </section>
    )
}

export default ContactsSection3;
