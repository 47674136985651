import React from "react";
import "./CompanySection5.scss";

import { motion } from "framer-motion";

const opacityX_30 = {
    hidden: {
        x: -50,
    },

    visible: {
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeOut',
        },
    },
}
const opacityX30 = {
    hidden: {
        x: 50,
    },

    visible: {
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeOut',
        },
    },
}

const CompanySection5 = () => {
    return (
        <section className="company5" >
            <div className="company5__page">
                <div className="company5__number1">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ amount: 0.9, once: true }}
                        variants={opacityX_30}
                        className="company5__block"
                    >
                        <span className="company5__block_big" >20</span>
                        <span className="company5__block_bigtext"  >лет на рынке</span>
                    </motion.div>

                    <div className="company5__text" >
                        <p>Интенсивный темп развития и внедрения новых технологий позволяет предлагать точные и проверенные решения</p>
                    </div>
                </div>
                <div className="company5__number2">
                    <motion.div className="company5__block">
                        <span className="company5__block_middle" >3</span>
                        <span className="company5__block_little" >филиала в России</span>
                    </motion.div>
                </div>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={opacityX30}
                    className="company5__number3"
                >
                    <div className="company5__block">
                        <span className="company5__block_middle" >250<span className="company5__block_plus">+</span></span>
                        <span className="company5__block_little"  >сотрудников</span>
                    </div>
                    <div className="company5__block">
                        <span className="company5__block_middle"  >60<span className="company5__block_plus">+</span></span>
                        <span className="company5__block_little" >крупных партнеров</span>
                    </div>
                </motion.div>

                <div className="company5__number4">
                    <div className="company5__block company5__block_1">
                        <span className="company5__block_middle">250<span className="company5__block_plus">+</span></span>
                        <span className="company5__block_little">сотрудников</span>
                    </div>
                    <div className="company5__block company5__block_2">
                        <span className="company5__block_middle">60<span className="company5__block_plus">+</span></span>
                        <span className="company5__block_little">крупных партнеров</span>
                    </div>
                    <div className="company5__block company5__block_3">
                        <span className="company5__block_middle">3</span>
                        <span className="company5__block_little">филиала в России</span>
                    </div>
                </div>

            </div>
            <div className="company5__BG1">
            </div>
        </section>
    )
}

export default CompanySection5;