import React from "react";

import "./HomeSection7.scss";

import tinkoff from "../../../../images/homeCustomers/tinkoff.svg";
import yaposha from "../../../../images/homeCustomers/yaposha.svg";
import Mayak_theater from "../../../../images/homeCustomers/Mayak_theater.svg";
import Elgacoal from "../../../../images/homeCustomers/Elgacoal.svg";

import Vnukovo_full from "../../../../images/homeCustomers/Vnukovo_full.svg";
import Azbuka from "../../../../images/homeCustomers/Azbuka.svg";
import Planet_Fit from "../../../../images/homeCustomers/Planet_Fit.svg";
import Beeline_Vympel from "../../../../images/homeCustomers/Beeline_Vympel.svg";

import UCOZ from "../../../../images/homeCustomers/UCOZ.svg";
import Wargaming from "../../../../images/homeCustomers/Wargaming.svg";
import Worldclass from "../../../../images/homeCustomers/Worldclass.svg";
import Shoko_full from "../../../../images/homeCustomers/Shoko_full.svg";

import RESO from "../../../../images/homeCustomers/RESO.svg";
import RZhD from "../../../../images/homeCustomers/RZhD.svg";
import Tatneft from "../../../../images/homeCustomers/Tatneft.svg";
import SAMSUNG from "../../../../images/homeCustomers/SAMSUNG.svg";

const arrCustomers = [
    tinkoff, yaposha, Mayak_theater, Elgacoal, Vnukovo_full, Azbuka, Planet_Fit, Beeline_Vympel,
    UCOZ, Wargaming, Worldclass, Shoko_full, RESO, RZhD, Tatneft, SAMSUNG
]

const HomeSection7 = () => (
    <section className="section section7">
        <div className="section7__iconswrapper">
            {arrCustomers.map((el, index) =>
                <div key={index} className="section7__icon">
                    <div className="section7__icon_wrapper">
                        <img src={el} alt="icon" />
                    </div>
                </div>
            )}
        </div>
    </section>
);

export default HomeSection7;
