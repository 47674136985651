import React, { useState } from "react";
import "./RecommendationsSection3.scss";

import PhotoLicense from "../../../../components/PhotoLicense/PhotoLicense";
import GalleryModal2 from "../../../../components/GalleryModal2/GalleryModal2";

import { arrRecommendations } from "../../../../data/recommendationsLicenses";

import { motion, AnimatePresence } from "framer-motion";

const opacity = {
    initial: {
        opacity: 0,
    },

    animate: {
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },
}

const RecommendationsSection3 = () => {
    const [visible, setVisible] = useState(-1);

    return (
        <section className="recommend3">
            <div className="recommend3__page">
                <div className="recommend3__licenses">
                    {
                        arrRecommendations.map((el, index) =>
                            <div className="recommend3__licens" key={index} >
                                <PhotoLicense name={el.name} position={el.position} src={el.photo} index={index} setVisible={setVisible} />
                            </div>
                        )
                    }
                </div>
            </div>

            <AnimatePresence exitBeforeEnter>
                {visible !== (-1) &&
                    <motion.div
                        initial={'initial'}
                        animate={'animate'}
                        exit={'exit'}
                        variants={opacity}
                    >
                        <GalleryModal2 arrPhoto={arrRecommendations} activeIndex={visible} setVisible={setVisible} />
                    </motion.div>
                }
            </AnimatePresence>

        </section>
    )
}

export default RecommendationsSection3;