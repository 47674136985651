import React from "react";
import { Route, Routes } from "react-router-dom";

import "./App.scss";

import Home from "./pages/Home/Home";
import Company from "./pages/Company/Company";
import Projects from "./pages/Projects/Projects";
import Partners from "./pages/Partners/Partners";
import Team from "./pages/Team/Team";
import Vacancy from "./pages/Vacancy/Vacancy";
import Notfound from "./pages/Notfound/Notfound";
import Contacts from "./pages/Contacts/Contacts";
import Recommendations from "./pages/Recommendations/Recommendations";
import Privacy from "./pages/Privacy/Privacy";
import Agreement from "./pages/Agreement/Agreement";
import Documents from "./pages/Documents/Documents.jsx";
import SiteMap from "./pages/SiteMap/SiteMap";

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/agreement" element={<Agreement />} />
                <Route path="/company" element={<Company />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/partners" element={<Partners />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/recommendations" element={<Recommendations />} />
                <Route path="/team" element={<Team />} />
                <Route path="/vacancy" element={<Vacancy />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/sitemap" element={<SiteMap />} />
                <Route path="*" element={<Notfound />} />
            </Routes>
        </>
    )
}

export default App;
