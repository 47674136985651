import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./Contacts.scss";

import ContactsSection1 from "./components/ContactsSection1/ContactsSection1";
import ContactsSection2 from "./components/ContactsSection2/ContactsSection2";
import ContactsSection3 from "./components/ContactsSection3/ContactsSection3";
import ContactsSection4 from "./components/ContactsSection4/ContactsSection4";
import Footer from "../../components/Footer/Footer.jsx";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Contacts = () => {
    useEffect(() => {
        window.scrollTo(0, 0);

        // if (location.hash.startsWith('#comment-')) {
        //     const comment = document.querySelector(location.hash);
        //     if (comment) {
        //         comment.scrollIntoView({ behavior: 'smooth' });
        //     }
        // }

    }, []);

    /*
        const smoothScroll = (event) => {
            //плавная прокрутка
            event.preventDefault();

            let element = document.querySelector('#form');
            element.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    */
    return (
        <div className="contacts" id="startpage">
            <Helmet>
                <title>Контакты</title>
                <meta name="description" content="Контакты офиса ФИЛАНКО в Москве, Санкт-Петербурге, Екатеринбурге. Мы всегда открыты для общения." />
            </Helmet>
            <Burger activeLink="contacts"/>
            <UpArrowPage />
            <ContactsSection1 />
            <ContactsSection2 />
            <ContactsSection3 />
            <ContactsSection4 />
            <Footer />
        </div>
    )
}

export default Contacts;
