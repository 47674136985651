import React, { useState, useEffect } from "react";
import styles from "./UpArrowPage.module.scss";

import upArrow from "./images/arrowDown.svg";

const UpArrowPage = () => {
    const [scrollDetection, setScrollDetection] = useState(0);

    const [way, setWay] = useState(false);

    const onScroll = () => {
        setScrollDetection(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    useEffect(() => {
        if (scrollDetection < 450 && way) {
            setWay(false);
        }

        if (scrollDetection >= 450 && way === false) {
            setWay(true);
        }
    }, [scrollDetection, way]);


    const smoothScroll = (event) => {
        event.preventDefault();

        let element = '';
        if (way) {
            element = document.querySelector('#startpage');
        }
        else {
            element = document.querySelector('#nextpage');
        }

        element.scrollIntoView({ //плавная прокрутка
            behavior: "smooth",
            block: "start"
        });
    }

    return (
        <div className={styles.arrow} onClick={smoothScroll}>
            <img src={upArrow} alt="arrow" className={way ? styles.active : ''} />
            <span>{way ? 'наверх' : 'дальше'}</span>
        </div>
    )
}

export default UpArrowPage;
