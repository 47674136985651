import React, { useState } from "react";
import "./CompanySection4.scss";

import { companyHistory } from "../../../../data/companyHistory";

import { motion, AnimatePresence } from "framer-motion";

const opacityX10 = {
    initial: {
        x: 20,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
            ease: 'circOut',
            once: false,
        },
    },

    exit: {
        opacity: 0,
        transition: {
            duration: 0.5,
        },
    }
}

const CompanySection4 = () => {
    const [activeClick, setActiveClick] = useState(0);

    return (
        <section className="company4" >
            <div className="company4__page">

                <div className="company4__heading" >
                    <h2>История успеха</h2>
                </div>

                <div className="company4__content">

                    <div className="company4__text">

                        <div className="company4__textheading" >
                            <AnimatePresence exitBeforeEnter>
                                {companyHistory.map((el, index) =>
                                    index === activeClick &&
                                    <motion.h3
                                        key={index}
                                        initial={'initial'}
                                        animate={'animate'}
                                        exit={'exit'}
                                        variants={opacityX10}
                                    >
                                        {el.heading}
                                    </motion.h3>
                                )}
                            </AnimatePresence>
                        </div>

                        <div className="company4__textbody" >
                            <AnimatePresence exitBeforeEnter>
                                {companyHistory.map((el, index) =>
                                    index === activeClick && <motion.p
                                        key={index}
                                        initial={'initial'}
                                        animate={'animate'}
                                        exit={'exit'}
                                        variants={opacityX10}
                                    > {el.history}</motion.p>
                                )}
                            </AnimatePresence>
                        </div>

                    </div>

                    <div className="company4__years" >
                        {companyHistory.map((el, index) =>
                            <span
                                key={index}
                                className={`company4__years_span ${index === activeClick ? 'company4__years_active' : ''}`}
                                onClick={() => setActiveClick(index)}
                            >{el.year}</span>
                        )}
                    </div>

                </div>

            </div>
        </section>
    )
}

export default CompanySection4;
