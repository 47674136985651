import React from "react";
import styles from "./LineOpacityRight.module.scss";

const LineOpacityRight = () => {
    return (
        <span className={styles.line}>
        </span>
    )
}

export default LineOpacityRight;