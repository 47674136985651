import React from "react";
import "./VacancySection3.scss";

const VacancySection3 = () => {
    return (
        <section className="vacancy3"  >
            <div className="vacancy3__page">
                <div className="vacancy3__ul1" >
                    <div className="vacancy3__heading1">
                        <h2>Мы гарантируем</h2>
                    </div>
                    <div className="vacancy3__text1">
                        <ul>
                            <li>Профессиональное развитие и карьерный рост</li>
                            <li>Обучение, тренинги, повышение квалификации</li>
                            <li>Участие в решении нетривиальных задач</li>
                            <li>Уютный офис и неповторимую атмосферу</li>
                            <li>Конкурентную заработную плату</li>
                            <li>ДМС и много разных плюшек</li>
                        </ul>
                    </div>
                </div>
                <div className="vacancy3__center">
                    <span></span>
                </div>
                <div className="vacancy3__ul2" >
                    <div className="vacancy3__heading2">
                        <h2>Хотим получить</h2>
                    </div>
                    <div className="vacancy3__text2">
                        <ul>
                            <li>Профессиональный подход</li>
                            <li>Нацеленность на результат</li>
                            <li>Максимальное проникновение в процессы</li>
                            <li>Высокий уровень ответственности</li>
                            <li>Желание развиваться</li>
                            <li>Мастерство работы в команде</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VacancySection3;