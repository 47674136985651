import React from "react";
import "./CompanySection3.scss";

import LinkArrowRight from "../../../../UI/LinkArrowRight/LinkArrowRight.jsx";

import { motion } from "framer-motion";

const opacityX_30 = {
    hidden: {
        x: -50,
    },

    visible: {
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeOut',
        },
    },
}

const opacityX30 = {
    hidden: {
        x: 50,
    },

    visible: {
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeOut',
        },
    },
}

const CompanySection3 = () => {
    return (
        <section className="company3" >
            <div className="company3__page">

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.9, once: true }}
                    variants={opacityX_30}
                    className="company3__number1"
                >
                    <div className="company3__text_big">
                        <span>50</span>
                    </div>
                    <div className="company3__text_right">
                        <div className="company3__text_middle" >
                            <span>+</span>
                        </div>
                        <div className="company3__text_little company3__text_little1">
                            <span>IT-услуг и&nbsp;направлений</span>
                        </div>
                    </div>
                </motion.div>

                <div className="company3__and">
                    <span className="company3__and_top"></span>
                    <span className="company3__and_central">и</span>
                    <span className="company3__and_bottom"></span>
                </div>

                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ amount: 0.7, once: true }}
                    variants={opacityX30}
                    className="company3__number2"
                >
                    <div className="company3__text_big" >
                        <span>9</span>
                    </div>
                    <div className="company3__text_right">
                        <div className="company3__text_little company3__text_little2">
                            <span>основных проектов</span>
                        </div>
                        <div className="company3__button">
                            <LinkArrowRight link='/projects#projects' alt=''>Ознакомиться</LinkArrowRight>
                        </div>
                    </div>
                </motion.div>

            </div>
        </section>
    )
}

export default CompanySection3;