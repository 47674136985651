import React, {useState, useEffect, useCallback} from "react";
import styles from "./GalleryModal2.module.scss";

import closeButton from "./images/closeButton.svg";
import arrow from "./images/arrow.png";
import arrow2 from "./images/arrow2.svg";

import { motion, AnimatePresence } from "framer-motion";

const opacityX20 = {
    initial: {
        x: 300,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: -300,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },
}
const opacityX_20 = {
    initial: {
        x: -300,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: 300,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },
}
const opacity = {
    initial: {
        // x: 300,
        opacity: 0,
    },

    animate: {
        // x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: -300,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },
}

const GalleryModal2 = ({ arrPhoto = [], activeIndex = 0, setVisible = () => { } }) => {
    const [numberPhoto, setNumberPhoto] = useState(activeIndex);
    const [wayG, setwayG] = useState('');

    useEffect(() => {
        let el = document.querySelector('body');
        el.style.overflow = 'hidden';

        return () => {
            let el = document.querySelector('body');
            el.style.overflow = 'visible';
        }
    }, [])

    const stopPR = (event) => {
        event.stopPropagation();

        if (event.clientX > document.documentElement.clientWidth / 2) {
            changePhoto(event, 'right')
        }
        else {
            changePhoto(event, 'left')
        }
    }

    const changePhoto = (event, way) => {
        event.stopPropagation()

        let nextPh = numberPhoto;
        if (way === 'right' && nextPh < arrPhoto.length - 1) {
            nextPh++;
        }
        else if (way === 'right' && nextPh === arrPhoto.length - 1) {
            nextPh = 0;
        }
        else if (way === 'left' && nextPh > 0) {
            nextPh--;
        }
        else if (way === 'left' && nextPh === 0) {
            nextPh = arrPhoto.length - 1;
        }

        setwayG(way);

        setNumberPhoto(nextPh);
    }

    const closeWindow = useCallback((event) => {
        event.preventDefault();

        if (event.key === 'Escape') {
            setVisible(-1);
        }
    }, [setVisible]);

    useEffect(() => {
        document.addEventListener('keydown', closeWindow);

        return () => {
            document.removeEventListener('keydown', closeWindow);
        };
    }, [closeWindow]);

    return (
        <div className={styles.gallerymodal} onClick={() => setVisible(-1)}>
            <div className={styles.gallerymodal__imgwrapper} >
                <AnimatePresence exitBeforeEnter>
                    {arrPhoto.map((element, index) =>
                        index === numberPhoto &&

                        <motion.img
                            initial={'initial'}
                            animate={'animate'}
                            exit={'exit'}
                            variants={wayG === 'left' ? opacityX_20 : wayG === 'right' ? opacityX20 : opacity}

                            onClick={stopPR}
                            key={index}
                            src={element.photo} alt=""
                        />

                    )}
                </AnimatePresence>

                <div className={styles.gallerymodal__button_2}>
                    <button className={styles.gallerymodal__leftbutton_2} onClick={(event) => changePhoto(event, 'left')}>
                        <img src={arrow2} alt="" />
                    </button>
                    <button className={styles.gallerymodal__rightbutton_2} onClick={(event) => changePhoto(event, 'right')}>
                        <img src={arrow2} alt="" />
                    </button>
                </div>

            </div>

            <button className={styles.gallerymodal__closebutton} onClick={() => setVisible(-1)}>
                <img src={closeButton} alt="" />
            </button>

            <button className={styles.gallerymodal__rightbutton} onClick={(event) => changePhoto(event, 'right')}>
                <img src={arrow} alt="" />
            </button>

            <button className={styles.gallerymodal__leftbutton} onClick={(event) => changePhoto(event, 'left')}>
                <img src={arrow} alt="" />
            </button>

        </div>
    )
}

export default GalleryModal2;
