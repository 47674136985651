import React, { useState } from "react";
import { motion } from "framer-motion";

import "./HomeSection5.scss";

const HomeSection5 = () => {
    const [bgEnterAnimation, setBgEnterAnimation] = useState(false);

    return (
        <section className="section section5">
            <motion.div
                viewport={{ once: false, amount: .01 }}
                onViewportEnter={() => setBgEnterAnimation(true)}
                onViewportLeave={() => setBgEnterAnimation(false)}
                className={`section5__bg1 ${bgEnterAnimation ? 'section5__bg1--enter' : ''}`}
            />

            <div className="section5__bg2"/>

            <div className="section5__content">

                <div className="section5__text" >
                    <div className="section5__textline">
                        <span className="section5__text_big section5__text_space">20</span><span className="section5__text_big">000</span><span className="section5__text_midle">+</span>
                    </div>
                    <span className="section5__text_small">клиентов&nbsp;и&nbsp;партнёров</span>
                </div>

                <div className="section5__text" >
                    <div className="section5__textline">
                        <span className="section5__text_big">20</span><span className="section5__text_midle">+</span>
                    </div>
                    <span className="section5__text_small">лет&nbsp;на&nbsp;рынке</span>
                </div>

            </div>
        </section>
    );
};

export default HomeSection5;
