import React from "react";
import "./VacancySection5.scss";

import { galleryPhoto } from "../../../../data/galleryPhoto";

import SliderLeftRightMobile3 from "../../../../components/SliderLeftRightMobile3/SliderLeftRightMobile3";
import GalleryFixPhoto from "../../../../components/GalleryFixPhoto/GalleryFixPhoto.jsx";

import { motion } from "framer-motion";

const VacancySection5 = () => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.4, once: true }}
            className="vacancy5"
        >

            <div className="vacancy5__page">
                <div className="vacancy5__heading">
                    <h3>Присоединяйтесь к нашей команде</h3>
                </div>

                <div className="vacancy5__gallery" >
                    <GalleryFixPhoto galleryPhoto={galleryPhoto} />
                </div>

                <div className="vacancy5__gallery2">
                    <SliderLeftRightMobile3 galleryPhoto={galleryPhoto} />
                </div>
            </div>

        </motion.section>
    )
}

export default VacancySection5;