import React from "react";
import "./RecommendationsSection2.scss";

import LineOpacityRight from "../../../../UI/LineOpacityRight/LineOpacityRight.jsx";

const RecommendationsSection2 = () => {
    return (
        <section
            id="nextpage"
            className="recommend2"
        >
            <div className="recommend2__page">
                <div className="recommend2__text">
                    <p>Мы гордимся реализованными проектами. Но ещё выше мы ценим желание продолжать сотрудничество в долгосрочной перспективе. Именно это желание говорит о самом важном факторе в современных бизнес&#8209;отношениях — о надёжности и доверии.</p>
                </div>
                <div className="recommend2__line">
                    <LineOpacityRight />
                </div>
            </div>
        </section>
    )
}
export default RecommendationsSection2;