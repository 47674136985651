import React from "react";
import styles from "./ModalInfo.module.scss";

import { motion, AnimatePresence } from "framer-motion";
const opacity = {
    initial: {
        opacity: 0,
    },

    animate: {
        opacity: 1,
        transition: {
            duration: 0.3,
            ease: 'easeOut',
        },
    },

    exit: {
        opacity: 0,
        transition: {
            duration: 0.3,
            ease: 'easeOut',
        },
    },
}

const ModalInfo = ({
    visible,
    setVisible,
    heading = "Спасибо за ваше обращение",
    text = "Мы свяжемся с вами в ближайшее время",
    img = ""
}) => {

    return (
        <AnimatePresence>
            {
                visible &&
                <motion.div
                    initial={'initial'}
                    animate={'animate'}
                    exit={'exit'}
                    variants={opacity}

                    className={`${styles.modal} `} onClick={() => setVisible(false)}
                >
                    <div className={styles.modal__page}>
                        <div className={styles.modal__heading}>
                            <h3>{heading}</h3>
                        </div>
                        <div className={styles.modal__content}>
                            <div className={styles.modal__img}>
                                <img src={img} alt="" />
                            </div>
                            <div className={styles.modal__text}>
                                <p>{text}</p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default ModalInfo;