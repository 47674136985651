export const documents = [
    {
        company: 'DATAHOUSE.RU',
        agreements: [
            {
                name: 'Пользовательское соглашение ООО «Ситителеком»',
                link: "/docs/polzovatelskoe-soglashenie-ooo-sititelekom.pdf",
                nds: true,
            },
            {
                name: 'Пользовательское соглашение ООО «Датахаус»',
                link: "/docs/polzovatelskoe-soglashenie-ooo-datahaus.pdf",
                nds: false,
            },
            {
                name: 'Соглашение об участии в реферальной программе',
                link: "/docs/soglashenye-ob-uchastii-v-referalnoi-programme-datahouse.pdf",
                nds: false,
            },
        ],
        rules: [
            {
                name: 'Политика в отношении защиты персональных данных',
                link: "/docs/politika-v-otnoshenii-zashchity-personalnyh-dannyh.pdf",
            },
            {
                name: 'Политика конфиденциальности',
                link: "/docs/politika-konfidencialnosti-i-nerazglasheniya.pdf",
            },
            {
                name: 'Политика предоставления возможности оплаты посредством регулярных автоматических переводов денежных средств — рекуррентных платежей',
                link: "/docs/politika-predostavleniya-vozmozhnosti-oplaty-posredstvom-regulyarnyh.pdf",
            },
            {
                name: 'Правила обращения в службу технической поддержки и клиентские службы',
                link: "/docs/pravila-vzaimodeystviya-so-sluzhboy-tehnicheskoy-podderzhki-i-klientskimi.pdf",
            },
            {
                name: 'Правила выполнения платных работ службами технической поддержки',
                link: "/docs/pravila-vypolneniya-platnyh-rabot-sluzhbami-tehnicheskoy-podderzhki.pdf",
            },
            {
                name: 'Правила посещения Дата-центра',
                link: "/docs/pravila-poseshcheniya-data-centra.pdf",
            }
        ],
        services: [
            {
                name: 'Размещение серверов и аренда стоек',
                link: "/docs/razmeshchenie-serverov-i-arenda-stoek.pdf",
            },
            {
                name: 'Выделенный сервер готовой и индивидуальной конфигурации',
                link: "/docs/vydelennyy-server-gotovoy-i-individualnoy-konfiguracii.pdf",
            },
            {
                name: 'Услуги администрирования',
                link: "/docs/uslugi-administrirovaniya.pdf",
            },
            {
                name: 'Публичное и приватное облако VMware',
                link: "/docs/publichnoe-i-privatnoe-oblako-vmware.pdf",
            },
            {
                name: 'Облачные серверы на платформе Datahouse Cloud',
                link: "/docs/oblachnye-servery-na-platforme-datahouse-cloud.pdf",
            }
        ]
    },
    {
        company: 'HOSTER.RU',
        agreements:[
            {
                name: 'Пользовательское соглашение ООО «Регистратор»',
                link: "/docs/polzovatelskoe-soglashenie-ooo-registrator.pdf",
            },
            {
                name: 'Соглашение об участии в реферальной программе',
                link: "/docs/soglashenye-ob-uchastii-v-referalnoi-programme-Hoster-ru.pdf",
            },
        ],
        rules:[
            {
                name: 'Политика в отношении защиты персональных данных',
                link: "/docs/politika-v-otnoshenii-zashchity-personalnyh-dannyh.pdf",
            },
            {
                name: 'Политика конфиденциальности',
                link: "/docs/politika-konfidencialnosti-i-nerazglasheniya.pdf",
            },
            {
                name: 'Политика предоставления возможности оплаты посредством регулярных автоматических переводов денежных средств — рекуррентных платежей',
                link: "/docs/politika-predostavleniya-vozmozhnosti-oplaty-posredstvom-regulyarnyh.pdf",
            },
            {
                name: 'Правила аннулирования и перерегистрации доменного имени, в случае ликвидации юридического лица или смерти физического лица',
                link: "/docs/Pravila_annulirovaniya_domennogo_imeni_v_sluchaye_likvidatsii_yuridicheskogo.pdf",
            },
            {
                name: 'Правила обращения в службу технической поддержки и клиентские службы',
                link: "/docs/pravila-vzaimodeystviya-so-sluzhboy-tehnicheskoy-podderzhki-i-klientskimi.pdf",
            },
            {
                name: 'Правила выполнения платных работ службами технической поддержки',
                link: "/docs/pravila-vypolneniya-platnyh-rabot-sluzhbami-tehnicheskoy-podderzhki.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в доменах .RU и .РФ',
                link: "/docs/rules_ru-rf.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в домене .SU',
                link: "/docs/SU_rules.pdf",
            }
        ],
        services:[
            {
                name: 'Виртуальный сервер VPS/VDS',
                link: "/docs/virtualnyy-server-vpsvds.pdf",
            },
            {
                name: 'Виртуальный хостинг и CMS хостинг',
                link: "/docs/virtualnyy-hosting-i-cms-hosting.pdf",
            },
            {
                name: 'Доменные имена',
                link: "/docs/domennye-imena.pdf",
            }
        ],
    },
    {
        company: 'HTS.RU',
        agreements:[
            {
                name: 'Пользовательское соглашение ООО «Регистратор»',
                link: "/docs/polzovatelskoe-soglashenie-ooo-registrator.pdf",
            },
            {
                name: 'Соглашение о предоставлении услуг в рамках Партнерской программы hts.ru – Агентский договор',
                link: "/docs/soglashenye-ob-uchastii-v-referalnoi-programme-hts-ru.pdf",
            }
        ],
        rules:[
            {
                name: 'Политика в отношении защиты персональных данных',
                link: "/docs/politika-v-otnoshenii-zashchity-personalnyh-dannyh.pdf",
            },
            {
                name: 'Политика конфиденциальности',
                link: "/docs/politika-konfidencialnosti-i-nerazglasheniya.pdf",
            },
            {
                name: 'Политика предоставления возможности оплаты посредством регулярных автоматических переводов денежных средств — рекуррентных платежей',
                link: "/docs/politika-predostavleniya-vozmozhnosti-oplaty-posredstvom-regulyarnyh.pdf",
            },
            {
                name: 'Правила аннулирования и перерегистрации доменного имени, в случае ликвидации юридического лица или смерти физического лица',
                link: "/docs/Pravila_annulirovaniya_domennogo_imeni_v_sluchaye_likvidatsii_yuridicheskogo.pdf",
            },
            {
                name: 'Правила обращения в службу технической поддержки и клиентские службы',
                link: "/docs/pravila-vzaimodeystviya-so-sluzhboy-tehnicheskoy-podderzhki-i-klientskimi.pdf",
            },
            {
                name: 'Правила выполнения платных работ службами технической поддержки',
                link: "/docs/pravila-vypolneniya-platnyh-rabot-sluzhbami-tehnicheskoy-podderzhki.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в доменах .RU и .РФ',
                link: "/docs/rules_ru-rf.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в домене .SU',
                link: "/docs/SU_rules.pdf",
            }
        ]
    },
    {
        company: 'HOSTLINE.RU',
        agreements:[
            {
                name: 'Пользовательское соглашение ООО «Регистратор»',
                link: "/docs/polzovatelskoe-soglashenie-ooo-registrator.pdf",
            },
        ],
        rules:[
            {
                name: 'Политика в отношении защиты персональных данных',
                link: "/docs/politika-v-otnoshenii-zashchity-personalnyh-dannyh.pdf",
            },
            {
                name: 'Политика конфиденциальности',
                link: "/docs/politika-konfidencialnosti-i-nerazglasheniya.pdf",
            },
            {
                name: 'Политика предоставления возможности оплаты посредством регулярных автоматических переводов денежных средств — рекуррентных платежей',
                link: "/docs/politika-predostavleniya-vozmozhnosti-oplaty-posredstvom-regulyarnyh.pdf",
            },
            {
                name: 'Правила аннулирования и перерегистрации доменного имени, в случае ликвидации юридического лица или смерти физического лица',
                link: "/docs/Pravila_annulirovaniya_domennogo_imeni_v_sluchaye_likvidatsii_yuridicheskogo.pdf",
            },
            {
                name: 'Правила обращения в службу технической поддержки и клиентские службы',
                link: "/docs/pravila-vzaimodeystviya-so-sluzhboy-tehnicheskoy-podderzhki-i-klientskimi.pdf",
            },
            {
                name: 'Правила выполнения платных работ службами технической поддержки',
                link: "/docs/pravila-vypolneniya-platnyh-rabot-sluzhbami-tehnicheskoy-podderzhki.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в доменах .RU и .РФ',
                link: "/docs/rules_ru-rf.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в домене .SU',
                link: "/docs/SU_rules.pdf",
            }
        ],
        services:[
            {
                name: 'Виртуальный сервер VPS/VDS',
                link: "/docs/virtualnyy-server-vpsvds.pdf",
            },
            {
                name: 'Виртуальный хостинг и CMS хостинг',
                link: "/docs/virtualnyy-hosting-i-cms-hosting.pdf",
            },
            {
                name: 'Доменные имена',
                link: "/docs/domennye-imena.pdf",
            }
        ],
    },
    {
        company: 'DOMENUS.RU',
        agreements:[
            {
                name: 'Пользовательское соглашение ООО «Регистратор»',
                link: "/docs/polzovatelskoe-soglashenie-ooo-registrator.pdf",
            },
            {
                name: 'Соглашение об участии в реферальной программе',
                link: "/docs/soglashenye-ob-uchastii-v-referalnoi-programme-domenus-ru.pdf",
            },
        ],
        rules:[
            {
                name: 'Политика в отношении защиты персональных данных',
                link: "/docs/politika-v-otnoshenii-zashchity-personalnyh-dannyh.pdf",
            },
            {
                name: 'Политика конфиденциальности',
                link: "/docs/politika-konfidencialnosti-i-nerazglasheniya.pdf",
            },
            {
                name: 'Политика предоставления возможности оплаты посредством регулярных автоматических переводов денежных средств — рекуррентных платежей',
                link: "/docs/politika-predostavleniya-vozmozhnosti-oplaty-posredstvom-regulyarnyh.pdf",
            },
            {
                name: 'Правила аннулирования и перерегистрации доменного имени, в случае ликвидации юридического лица или смерти физического лица',
                link: "/docs/Pravila_annulirovaniya_domennogo_imeni_v_sluchaye_likvidatsii_yuridicheskogo.pdf",
            },
            {
                name: 'Правила обращения в службу технической поддержки и клиентские службы',
                link: "/docs/pravila-vzaimodeystviya-so-sluzhboy-tehnicheskoy-podderzhki-i-klientskimi.pdf",
            },
            {
                name: 'Правила выполнения платных работ службами технической поддержки',
                link: "/docs/pravila-vypolneniya-platnyh-rabot-sluzhbami-tehnicheskoy-podderzhki.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в доменах .RU и .РФ',
                link: "/docs/rules_ru-rf.pdf",
            },
            {
                name: 'Правила регистрации доменных имен в домене .SU',
                link: "/docs/SU_rules.pdf",
            },
            {
                name: 'Правила совершения безбумажных операций с доменами',
                link: "/docs/pravila-soversheniya-bezbumazhnykh-operaczij-s-domenami.pdf",
            },
        ],
        services:[
            {
                name: 'Виртуальный сервер VPS/VDS',
                link: "/docs/virtualnyy-server-vpsvds.pdf",
            },
            {
                name: 'Виртуальный хостинг и CMS хостинг',
                link: "/docs/virtualnyy-hosting-i-cms-hosting.pdf",
            },
            {
                name: 'Доменные имена',
                link: "/docs/domennye-imena.pdf",
            }
        ],
    },
];
