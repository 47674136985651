import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import "./HomeSection6.scss";

import arrow from "../../../../images/arrow.svg";

const CURRENT_YEAR = new Date().getFullYear();

const HomeSection6 = () => {
    const [bgEnterAnimation, setBgEnterAnimation] = useState(false);

    return (
        <section className="section section6">
            <motion.div
                viewport={{ once: false, amount: .01 }}
                onViewportEnter={() => setBgEnterAnimation(true)}
                onViewportLeave={() => setBgEnterAnimation(false)}
                className={`section6__BG1 ${bgEnterAnimation ? 'section6__BG1--enter' : ''}`}
            />

            <div className="section6__BG2"/>

            <div className="section6__content">
                <div className="section6__text" >
                    <p>Создаём технологии и решения для эффективного управления бизнес&#8209;процессами</p>
                </div>

                <div className="section6__links">
                    <div className="section6__link">
                        <div className="section6__arrow" >
                            <img src={arrow} alt="arrow" />
                        </div>
                        <div>
                            <Link to="/projects">Проекты</Link>
                        </div>
                    </div>
                    <div className="section6__link">
                        <div className="section6__arrow" >
                            <img src={arrow} alt="arrow" />
                        </div>
                        <div>
                            <Link to="/partners">Партнерам</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section6__footer" >
                <div className="section6__line"/>
                <div className="section6__footercontent">
                    <div className="section6__copyright">© 1999-{CURRENT_YEAR} ГК Филанко. Все права защищены</div>
                    <div className="section6__agreement"><Link to="/privacy">Политика конфиденциальности</Link></div>
                </div>
            </div>

        </section>
    );
}

export default HomeSection6;
