import React from "react";
import "./PartnersSection8.scss";

const PartnersSection8 = () => {

    return (
        <section className="partners8" >
            <div className="partners8__page">
                <div className="partners8__text" >
                    <p>«Благодарим наших партнёров, которые за долгие годы плодотворной работы стали для нас друзьями. За их вклад, за возможность совместного роста. Мы гордимся нашим сотрудничеством!»</p>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection8;