//описание страницы
import React from "react";
import classes from "./DescriptionPage.module.scss";

const DescriptionPage = ({ text = 'text' }) => {

    return (
        <div className={`${classes.description} `}>
            <span className={classes.description__line}></span>
            <span className={classes.description__text}>{text}</span>
        </div>
    )
}

export default DescriptionPage;