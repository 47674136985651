import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./Company.scss";

import CompanySection1 from "./components/CompanySection1/CompanySection1.jsx";
import CompanySection2 from "./components/CompanySection2/CompanySection2.jsx";
import CompanySection3 from "./components/CompanySection3/CompanySection3.jsx";
import CompanySection4 from "./components/CompanySection4/CompanySection4.jsx";
import CompanySection5 from "./components/CompanySection5/CompanySection5.jsx";
import CompanySection6 from "./components/CompanySection6/CompanySection6.jsx";

import Footer from "../../components/Footer/Footer";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Company = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="company" id="startpage">
            <Helmet>
                <title>О компании</title>
                <meta name="description" content="За 20 лет группа компаний ФИЛАНКО прошла успешную трансформацию из оператора связи, до интегратора ИТ-услуг и сервисов." />
            </Helmet>
            <Burger activeLink="company"/>
            <UpArrowPage />
            <CompanySection1 />
            <CompanySection2 />
            <CompanySection3 />
            <CompanySection4 />
            <CompanySection5 />
            <CompanySection6 />
            <Footer />
        </div>
    )
}

export default Company;

