import React from "react";
import './PartnersSection2.scss';

import LineOpacityRight from "../../../../UI/LineOpacityRight/LineOpacityRight.jsx";
import arrow from "../../../../images/arrow.svg";

const PartnersSection2 = () => {

    const smoothScroll = (event) => {
        //плавная прокрутка
        event.preventDefault();

        let element = document.querySelector('#payments');
        element.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }

    return (
        <section
            id="nextpage"
            className="partners2"
        >
            <div className="partners2__page">
                <div className="partners2__part1">
                    <div className="partners2__heading">
                        <h2 >Партнерам</h2>
                    </div>
                    <div className="partners2__content1">
                        <div className="partners2__text1">
                            <p>Приглашаем к участию в нашей партнёрской программе всех, кто хочет получить постоянный пассивный доход и гарантированные выплаты. Партнёрская программа ФИЛАНКО включает множество проектов и направлений: интернет, телефония, хостинг, монтаж СКС, услуги ЦОД.</p>
                        </div>
                        <div className="partners2__link">
                            <a href="#payments" onClick={smoothScroll}>
                                <img src={arrow} alt="" />
                                <span>Подробнее</span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="partners2__part2">
                    <div className="partners2__content2">
                        <div className="partners2__line">
                            <LineOpacityRight />
                        </div>
                        <div className="partners2__text2">
                            <p>Среди ваших знакомых есть директора и&nbsp;IT&#8209;руководители? Помогите решить их&nbsp;задачи, мы вам за&nbsp;это заплатим.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection2;