// 
export const vacancy = [
    {
        vacancy:'Вакансии на HeadHunter',
        pay: '',
        url: 'https://hh.ru/employer/14805'
    },
    {
        vacancy:'Вакансии на SuperJob',
        pay: '',
        url: 'https://www.superjob.ru/clients/filanko-176150/vacancies.html'
    },
    // {
    //     vacancy:'Инженер технической поддержки телефонии',
    //     pay: 'от 80 000 ₽'
    // },
    // {
    //     vacancy:'Менеджер по работе с комерческой недвижимостью',
    //     pay: '30 000-50 000 ₽'
    // },
    // {
    //     vacancy:'SEO-копирайтер',
    //     pay: 'По договоренности'
    // },
]