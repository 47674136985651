import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./Recommendations.scss";

import RecommendationsSection1 from "./components/RecommendationsSection1/RecommendationsSection1";
import RecommendationsSection2 from "./components/RecommendationsSection2/RecommendationsSection2";
import RecommendationsSection3 from "./components/RecommendationsSection3/RecommendationsSection3";
import Footer from "../../components/Footer/Footer.jsx";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Recommendations = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="recommend" id="startpage">
            <Helmet>
                <title>Рекомендации</title>
                <meta name="description" content="Мнение клиентов и партнерах о нашей работе является важнейшим индикатором и стимулом к развитию." />
            </Helmet>
            <Burger activeLink="recommendations" />
            <UpArrowPage />
            <RecommendationsSection1 />
            <RecommendationsSection2 />
            <RecommendationsSection3 />
            <Footer />
        </div>
    )
}

export default Recommendations;
