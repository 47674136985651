import React from "react";
// import { Link } from "react-router-dom";
import "./VacancySection4.scss";

import arrow from "../../../../images/arrow_59px.svg";

import { vacancy } from "../../../../data/vacancy";

const VacancySection4 = () => {
    return (
        <section className="vacancy4" >
            <div className="vacancy4__page">
                <div className="vacancy4__head">
                    <div className="vacancy4__heading">
                        <h2>Ознакомьтесь с&nbsp;вакансиями</h2>
                    </div>

                    <div className="vacancy4__contacts">
                        <div className="vacancy4__welcome">
                            <p>Вы всегда можете отправить своё резюме или связаться с нами</p>
                        </div>
                        <div className="vacancy4__data">
                            <div className="vacancy4__mail">
                                <span>Адрес для резюме: </span>
                                <a href="mailto:hr@filanco.ru">hr@filanco.ru</a>
                            </div>
                            <div className="vacancy4__phones">
                                <span>Телефоны: </span>
                                <div className="vacancy4__phones_inline">
                                    <a href="tel:+74953630542">8&nbsp;(495)&nbsp;363&#8209;05&#8209;42, </a>
                                    <a href="tel:+79031234763">8&nbsp;(903)&nbsp;123&#8209;47&#8209;63</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="vacancy4__vacancies">
                    <ul>
                        {vacancy.map((el, index) =>
                            <li key={index}  >
                                <span className="vacancy4__vacancies_first">{el.vacancy}</span>
                                <div><span className="vacancy4__vacancies_second">{el.pay}</span><a href={el.url} target="_blank" rel="noreferrer"><img src={arrow} alt="" /></a></div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default VacancySection4;