import React from "react";
import "./PartnersSection6.scss";

import LinkArrowRight from "../../../../UI/LinkArrowRight/LinkArrowRight.jsx"

const PartnersSection6 = () => {
    return (
        <section className="partners6" >
            <div className="partners6__page">
                <h3 >Задайте вопросы по партнёрским программам</h3>
                <div className="partners6__content">
                    <div className="partners6__text" >
                        <p>Проконсультируем, поможем с печатными и информационными материалами или публикациями. Наша партнёрская программа отличается открытостью и лояльностью.</p>
                    </div>
                    <div className="partners6__text2">
                        <div className="partners6__quote" >
                            <p>Мы для того, чтобы вам было комфортней</p>
                        </div>
                        <div className="partners6__button" >
                            <LinkArrowRight link='/contacts#form' alt=''>Связаться</LinkArrowRight>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PartnersSection6;