import React from "react";

import classes from "./Description.module.scss";

const Description = ({ text, active }) => {
    if (!text) {
        return null;
    }

    return (
        <div className={classes.description}>
            <span className={`${classes.description__line} ${active ? classes['description__line--active'] : ''}`}/>
            <div>
                <span className={`${classes.description__text} ${active ? classes['description__text--active'] : ''}`}>{text}</span>
            </div>
        </div>
    );
};

export default Description;
