//
import FSK from "../images/partnersBrands/FSK.svg";
import FSK_inactive from "../images/partnersBrands/FSK_inactive.svg";

import Vympelcom_Beeline from "../images/partnersBrands/Vympelcom_Beeline.svg";
import Vympelcom_Beeline_inactive from "../images/partnersBrands/Vympelcom_Beeline_inactive.svg";

import TSODy_rf from "../images/partnersBrands/TSODy_rf.svg";
import TSODy_rf_inactive from "../images/partnersBrands/TSODy_rf_inactive.svg";

import MGTS from "../images/partnersBrands/MGTS.svg";
import MGTS_inactive from "../images/partnersBrands/MGTS_inactive.svg";

import Obit20 from "../images/partnersBrands/20Obit.svg";
import Obit20_inactive from "../images/partnersBrands/20Obit_inactive.svg";

import TV24 from "../images/partnersBrands/24TV.svg";
import TV24_inactive from "../images/partnersBrands/24TV_inactive.svg";

import ER_Telecom from "../images/partnersBrands/ER_Telecom.svg";
import ER_Telecom_inactive from "../images/partnersBrands/ER_Telecom_inactive.svg";

import MegaFon from "../images/partnersBrands/MegaFon.svg";
import MegaFon_inactive from "../images/partnersBrands/MegaFon_inactive.svg";

import Smotreshka from "../images/partnersBrands/Smotreshka.svg";
import Smotreshka_inactive from "../images/partnersBrands/Smotreshka_inactive.svg";

export const partnersBrands = [
    {
        srcActive: FSK,
        srcPassive: FSK_inactive,
        alt: 'ФСК',
    },
    {
        srcActive: Vympelcom_Beeline,
        srcPassive: Vympelcom_Beeline_inactive,
        alt: 'Билайн',
    },
    {
        srcActive: TSODy_rf,
        srcPassive: TSODy_rf_inactive,
        alt: 'Цоды',
    },
    {
        srcActive: MGTS,
        srcPassive: MGTS_inactive,
        alt: 'МГТС',
    },
    {
        srcActive: Obit20,
        srcPassive: Obit20_inactive,
        alt: 'Обит',
    },
    {
        srcActive: TV24,
        srcPassive: TV24_inactive,
        alt: '24ТВ',
    },
    {
        srcActive: ER_Telecom,
        srcPassive: ER_Telecom_inactive,
        alt: 'ЭР телеком',
    },
    {
        srcActive: MegaFon,
        srcPassive: MegaFon_inactive,
        alt: 'Мегафон',
    },
    {
        srcActive: Smotreshka,
        srcPassive: Smotreshka_inactive,
        alt: 'Смотрешка',
    },
];