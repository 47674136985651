import React, { useState } from "react";
import styles from "./PhotoLicense.module.scss";

import zoom from "./images/zoom_icon.png";

const PhotoLicense = ({ name = 'name', position = 'position', src = '/', alt = '', index = 0, setVisible = () => { } }) => {
    const [hover, setHover] = useState(false);


    const openModal = () => {
        setHover(false);
        setVisible(index);
    }

    return (
        <div className={styles.photolicense}>
            <div className={styles.photolicense__imgwrapper} onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={openModal}>
                <img
                    className={styles.photolicense__img}
                    src={src}
                    alt={alt}
                />
                <div className={`${styles.photolicense__zoom} ${hover ? styles.photolicense__zoom_active : styles.photolicense__zoom_passive}`} >
                    <img
                        src={zoom}
                        alt="zoom"
                        className={`${styles.photolicense__plus} ${hover ? styles.photolicense__plus_active : styles.photolicense__plus_passive}`}
                    />
                </div>
            </div>
            <span className={styles.photolicense__name}>{name}</span>
            <span className={styles.photolicense__position}>{position}</span>
        </div>
    )
}

export default PhotoLicense;