import React, { useState } from "react";

import "./SliderLeftRightMobile3.scss";

import arrow from "./images/arrow_59px.svg";

import { motion, AnimatePresence } from "framer-motion";

const opacityX20 = {
    initial: {
        x: 300,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: -300,
        opacity: 0,
        transition: {
            duration: 0.1,
            ease: 'easeOut',
        },
    },
}
const opacityX_20 = {
    initial: {
        x: -300,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: 300,
        opacity: 0,
        transition: {
            duration: 0.1,
            ease: 'easeOut',
        },
    },
}
const opacity = {
    initial: {
        opacity: 0,
    },

    animate: {
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        opacity: 0,
        transition: {
            duration: 0.1,
            ease: 'easeOut',
        },
    },
}

const SliderLeftRightMobile3 = ({ galleryPhoto = [] }) => {
    const [numberImg, setNumberImg] = useState(0);      //номер картинки от 0
    const [wayG, setwayG] = useState('');

    const [moveX, setMoveX] = useState(0);
    const [moveY, setMoveY] = useState(0);

    const changePhoto = (way) => {
        let numI = numberImg;
        if (way === 'left' && numI > 0) {
            numI--;
        }
        else if (way === 'left' && numI === 0) {
            numI = galleryPhoto.length - 1;
        }
        else if (way === 'right' && numI < galleryPhoto.length - 1) {
            numI++;
        }
        else if (way === 'right' && numI === galleryPhoto.length - 1) {
            numI = 0;
        }

        setNumberImg(numI);
        setwayG(way);
    }

    const dragStart = (event) => {
        setMoveX(event.changedTouches[0].clientX);
        setMoveY(event.changedTouches[0].clientY);
    }

    const dragEnd = (event) => {
        let mvY = moveY - event.changedTouches[0].clientY;
        let mvX = moveX - event.changedTouches[0].clientX;

        if (Math.abs(mvY * 2) > Math.abs(mvX + 10)) { //убираю диагональный скрол, остается горизонтальный, притупил на 10 пикс
            return;
        }

        if (mvX > 0) {
            changePhoto('right');
        }
        if (mvX < 0) {
            changePhoto('left');
        }
    }

    return (
        <div className="slidermobile3">
            <div className="slidermobile3__photo"
                onTouchEnd={dragEnd}
                onTouchStart={dragStart}
            >

                <AnimatePresence exitBeforeEnter>
                    {galleryPhoto.map((el, index) =>
                        index === numberImg &&
                        <motion.div
                            initial={'initial'}
                            animate={'animate'}
                            exit={'exit'}
                            variants={wayG === 'left' ? opacityX_20 : wayG === 'right' ? opacityX20 : opacity}

                            className="slidermobile3__photoimg"
                            key={index}
                        >
                            <img src={el} alt="" />
                        </motion.div>
                    )}
                </AnimatePresence>

            </div>
            <div className="slidermobile3__buttons">
                <div className="slidermobile3__buttons_left" onClick={() => changePhoto('left')}>
                    <img src={arrow} alt="" />
                </div>
                <div className="slidermobile3__buttons_right" onClick={() => changePhoto('right')}>
                    <img src={arrow} alt="" />
                </div>
            </div>
        </div >
    )
}

export default SliderLeftRightMobile3;