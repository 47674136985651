import React from "react";
import "./ContactsSection2.scss";

import arrow from "../../../../images/arrow.svg";

const ContactsSection2 = () => {
    const smoothScroll = (event) => {
        //плавная прокрутка
        event.preventDefault();

        let element = document.querySelector('#form');
        element.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }

    return (
        <section className="contacts2">
            <div
                id="nextpage"
                className="contacts2__page"
            >
                <div className="contacts2__write">
                    <span >Есть вопросы по сотрудничеству<br />или нашим проектам?</span>
                    <div className="contacts2__link" >
                        <a href="#form" onClick={smoothScroll}>
                            <img src={arrow} alt="" />
                            <span>Написать нам</span>
                        </a>
                    </div>
                </div>

                <div className="contacts2__phone" >
                    <span><a href="tel:+78002000542">8 800 200-05-42</a></span>
                    <span>Бесплатный звонок по России</span>
                    <span></span>
                </div>
            </div>
        </section>
    )
}
export default ContactsSection2;
