import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./Partners.scss";

import Footer from "../../components/Footer/Footer.jsx";

import PartnersSection9 from "./components/PartnersSection9/PartnersSection9";
import PartnersSection8 from "./components/PartnersSection8/PartnersSection8";
import PartnersSection7 from "./components/PartnersSection7/PartnersSection7";
import PartnersSection6 from "./components/PartnersSection6/PartnersSection6";
import PartnersSection5 from "./components/PartnersSection5/PartnersSection5";
import PartnersSection4 from "./components/PartnersSection4/PartnersSection4";
import PartnersSection3 from "./components/PartnersSection3/PartnersSection3";
import PartnersSection2 from "./components/PartnersSection2/PartnersSection2";
import PartnersSection1 from "./components/PartnersSection1/PartnersSection1";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Partners = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="partners" id="startpage">
            <Helmet>
                <title>Партнерам</title>
                <meta name="description" content="Станьте партнером и получайте стабильный пассивный доход. Мы ценим стремление и желание расти. Зарабатывайте вместе с ФИЛАНКО." />
            </Helmet>
            <Burger activeLink="partners" />
            <UpArrowPage />
            <PartnersSection1 />
            <PartnersSection2 />
            <PartnersSection3 />
            <PartnersSection4 />
            <PartnersSection5 />
            <PartnersSection6 />
            <PartnersSection7 />
            <PartnersSection8 />
            <PartnersSection9 />
            <Footer />
        </div>
    )
}

export default Partners;
