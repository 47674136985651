import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./Vacancy.scss";

import VacancySection1 from "./components/VacancySection1/VacancySection1";
import VacancySection2 from "./components/VacancySection2/VacancySection2";
import VacancySection3 from "./components/VacancySection3/VacancySection3";
import VacancySection4 from "./components/VacancySection4/VacancySection4";
import VacancySection5 from "./components/VacancySection5/VacancySection5";
import VacancySection6 from "./components/VacancySection6/VacancySection6";
import Footer from "../../components/Footer/Footer";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Vacancy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="vacancy" id="startpage">
            <Helmet>
                <title>Вакансии</title>
                <meta name="description" content="Присылайте резюме и приходите в гости, если вы заряжены на результат и полны идей для совместного роста. Будем рады знакомству." />
            </Helmet>
            <Burger activeLink="vacancy" />
            <UpArrowPage />
            <VacancySection1 />
            <VacancySection2 />
            <VacancySection3 />
            <VacancySection4 />
            <VacancySection5 />
            <VacancySection6 />
            <Footer />
        </div>
    )
}

export default Vacancy;
