import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./Projects.scss";

import ProjectsSection1 from "./components/ProjectsSection1/ProjectsSection1.jsx";
import ProjectsSection2 from "./components/ProjectsSection2/ProjectsSection2.jsx";
import ProjectsSection3 from "./components/ProjectsSection3/ProjectsSection3.jsx";

import Footer from "../../components/Footer/Footer";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Projects = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="projects" id="startpage">
            <Helmet>
                <title>Проекты</title>
                <meta name="description" content="Интернет в офис, СКС, телефония, хостинг, аренда и размещение серверов и еще более 20 услуг для вашего бизнеса." />
            </Helmet>
            <Burger activeLink="projects" />
            <UpArrowPage />
            <ProjectsSection1 />
            <ProjectsSection2 />
            <ProjectsSection3 />
            <Footer />
        </div>
    )
}

export default Projects;
