import React from "react";
import "./SiteMap.scss";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Burger from "../../components/Burger/Burger";

const SiteMap = () => {
    return (
        <>
            <Helmet>
                <title>Карта сайта</title>
                <meta name="description" content="" />
            </Helmet>
            <Burger activeLink="" />

            <div className="sitemap">

                <div className="sitemap__page">
                    <h1>Карта сайта</h1>

                    <ul>
                        <li><Link to="/">Главная</Link></li>
                        <li><Link to="/company">Компания</Link></li>
                        <li><Link to="/projects">Проекты</Link></li>
                        <li><Link to="/team">Команда</Link></li>
                        <li><Link to="/partners">Партнерам</Link></li>
                        <li><Link to="/documents">Документы</Link></li>
                        <li><Link to="/recommendations">Рекомендации</Link></li>
                        <li><Link to="/contacts">Контакты</Link></li>
                        <li><Link to="/vacancy">Вакансии</Link></li>
                        <li><Link to="/agreement">Пользовательское соглашение</Link></li>
                        <li><Link to="/vacancy">Политика в отношении защиты персональных данных</Link></li>
                    </ul>
                </div>

                <div className="sitemap__footer">
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default SiteMap;