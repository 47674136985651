import React from "react";
import "./VacancySection2.scss";

const VacancySection2 = () => {
    return (
        <section
            id="nextpage"
            className="vacancy2"
        >
            <div className="vacancy2__page">
                <div className="vacancy2__text1">
                    <p>Главным критерием для нас являются успешные результаты, готовность к сложным задачам и высокой ответственности.</p>
                </div>
                <div className="vacancy2__text2" >
                    <p>Мы ищем тех, кто сможет внести вклад в развитие нашей компании. Мы хотим работать с теми, для кого важно быть лучшими в том, что они делают. С теми, кто не привык останавливаться на достигнутом, готов учиться и побеждать, работать над собой и совершенствоваться.</p>
                </div>
            </div>
        </section>
    )
}

export default VacancySection2;