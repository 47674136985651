import React from "react";
import style from "./HeadingH1.module.scss";

const HeadingH1 = ({ textLine1 = 'H1', textLine2 = '' }) => {
    return (
        <h1 className={style.h1}>
            {textLine1}
            <br />
            {textLine2}
        </h1>
    )
}

export default HeadingH1;