import React, { useEffect } from "react";
import "./Agreement1.scss";

const Agreement1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="agreement1">
            <div className="agreement1__page">
                <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
                <p>г. Москва </p>
                <p>«15» марта 2022 г.</p>
                <p>Настоящее Пользовательское соглашение (далее — Соглашение) регулирует отношения между Обществом с ограниченной
                    ответственностью «СИТИТЕЛЕКОМ» (ООО «СИТИТЕЛЕКОМ») (далее — Исполнитель) и неограниченным кругом физических
                    и юридических лиц (далее — Заказчик), желающих получить доступ к Услугам и Сервисам, предоставляемым Исполнителем.</p>
                <h2>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
                <p>Пользователь — любой представитель Заказчика, имеющий доступ к Панели управления.</p>
                <p>Услуга — совокупность или объединенная часть сервисов, потребляемая Заказчиком в результате предоставления Исполнителем
                    доступа к таким сервисам.</p>
                <p>Сервис — отдельный компонент или часть Услуги, потребляемый Заказчиком в результате предоставления Исполнителем доступа
                    к такому сервису.</p>
                <p>Опция — вспомогательный Сервис, не включенный в основную часть Услуги, являющийся дополнением или расширением
                    функциональности.</p>
                <p>Функционал — набор возможностей, характеристик и качеств, получаемых Заказчиком в период пользования Услугами и Сервисами.
                    Панель управления — программно-аппаратный комплекс Исполнителя, предназначенный для заказа и управления Услугами
                    и Сервисами.</p>
                <p>Учетная запись — пара логин-пароль для доступа в Панель управления, присвоенная Пользователю согласно указанным при
                    регистрации данным.</p>
                <p>Логин — уникальный идентификатор, выданный Заказчику при регистрации в Панели управления и позволяющий однозначно его
                    идентифицировать.</p>
                <p>Тикет-система — система обмена сообщениями между Заказчиком и Исполнителем путем отправки/получения запросов через
                    электронную форму, расположенную в Панели управления.</p>
                <p>Лицевой счет — совокупность записей в Панели управления, отражающих финансовые взаимоотношения между Исполнителем
                    и Заказчиком. Лицевой счет Заказчика увеличивается на сумму платежей, перечисленных Заказчиком Исполнителю в счет предоплаты
                    Услуг и Сервисов, оказываемых Исполнителем Заказчику, и уменьшается на стоимость выбранных и подключенных Заказчиком Услуг
                    и Сервисов. Исполнитель выделяет следующие типы лицевых счетов:</p>
                <p>- Основной: доступен юридическим лицам, физическим лицам и ИП. Зачисление на этот тип счета производится при безналичном
                    варианте оплаты и при электронных платежах для Учетных записей физических лиц.</p>
                <p>- Электронный: доступен юридическим лицам и ИП. Зачисления на этот тип счета производятся при поступлении электронных
                    платежей.</p>
                <p>- Партнерский: доступен Заказчикам, заключившим Партнерское соглашение о предоставлении услуг. На данный тип счета
                    производится премиальные зачисления, за реализованные Партнером Услуги.</p>
                <p>- Бонусный: доступен юридическим лицам, физическим лицам и ИП. На это тип счета зачисляются компенсации, бонусы, премии
                    и вознаграждения. Не является финансовым счетом, средства, находящиеся на данном типе счета, могут быть направлены
                    исключительно на пользование Услугами.</p>
                <p>Техническая площадка — физический или виртуальный комплекс, построенный на оборудовании (серверах) Исполнителя,
                    размещенный в специализированном помещении (в Дата-центре).</p>
                <p>Дата-центр — специализированное здание для размещения серверов и иного оборудования.</p>
                <h2>1. ПРЕДМЕТ И АКЦЕПТ СОГЛАШЕНИЯ</h2>
                <p>1.1. Предметом Соглашения является предоставление Заказчику доступа к Услугам и Сервисам Исполнителя (далее Услуги).
                    Исполнитель оказывает Услуги Заказчику в соответствии с Условиями данного Соглашения, Условиями использования отдельных
                    Услуг и Сервисов, а также положениями других документов и Правил, опубликованных на сайте Исполнителя (далее  Условия
                    и Правила).</p>
                <p>1.2. В соответствии со статьей 437 Гражданского кодекса Российской Федерации данное Соглашение является офертой, поэтому
                    Исполнитель должен внимательно ознакомиться с условиями Соглашения, Условиями и правилами оказания Услуг и Сервисов
                    и в случае несогласия отказаться от акцептирования условий.</p>
                <p>1.3. Безусловным принятием (акцептом) условий настоящего Соглашения, Условий и Правил является получение Заказчиком
                    реквизитов доступа к Панели управления услугами или к отдельным Услугам и Сервисам, и/или осуществление платежа в счет оплаты
                    Услуг и/или получение соответствующего финансового документа, подтверждающего факт оплаты.</p>
                <h2>2. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
                <p>2.1. Исполнитель обязан</p>
                <p>2.1.1. Обеспечить беспрепятственную регистрацию и создание Учетной записи Заказчика в электронной системе учета Исполнителя
                    (далее Биллинг), предоставив тем самым доступ к Панели управления с возможностью заказа и управления Услугами</p>
                <p>2.1.2. Предоставлять Заказчику Услуги согласно условиям действующего Соглашения, Условиям и Правилам, за исключением сроков
                    проведения необходимых профилактических и ремонтных работ, о которых Исполнитель уведомляет Заказчика не менее чем за 3 (три)
                    календарных дня.</p>
                <p>2.1.3. Отправлять все уведомления, запросы или уточнения исключительно на контактный электронный адрес Заказчика
                    (далее - e-mail), указанный в личном кабинете Заказчика на момент отправки такого запроса или уточнения.</p>
                <p>2.1.4. Своевременно оповещать Заказчика о любых изменениях в условиях предоставления Услуг путем публикации таких изменений
                    на сайте Исполнителя не менее, чем за 10 (десять) календарных дней до введения таких изменений, в том числе об изменении тарифов
                    на Услуги Исполнителя.</p>
                <p>2.1.5. Предпринимать все необходимые меры и действия для обеспечения конфиденциальности информации, получаемой или
                    отправляемой Заказчиком.</p>
                <p>2.2. Исполнитель имеет право</p>
                <p>2.2.1. Предоставить доступ к Услугам и Сервисам после проведения идентификации или верификации данных, предоставленных при
                    регистрации Заказчиком.</p>
                <p>2.2.2. В любое время без предварительного уведомления осуществить проверку предоставленной Заказчиком регистрационной
                    информации и запросить дополнительные сведения или документы с целью подтверждения корректности и актуальности этой
                    информации в случае возникновения у Исполнителя сомнений относительно её полноты, достоверности и актуальности.</p>
                <p>2.2.3. Потребовать от Заказчика или взыскать компенсацию затрат на проверку и изменение регистрационных данных в случае
                    выявления несоответствий между регистрационной информацией и документами, предоставленными для её подтверждения.
                    Взыскание и компенсация производится путем списания денежных средств с лицевого счета Заказчика, в том числе с сокращением
                    срока действующих Услуг, или с формированием отрицательного баланса лицевого счета.</p>
                <p>2.2.4. Потребовать от Заказчика или взыскать дополнительную плату, если по вине или инициативе последнего объем фактически
                    оказанных Услуг превысил объем Услуг, предусмотренных условиями Соглашения, Условиями и Правилами.</p>
                <p>2.2.5. Исполнитель вправе выполнять непрерывное сканирование и проверку размещенных web-ресурсов (и серверов) Заказчика,
                    приостановить оказание Услуг путем их отключения, заблокировать доступ в Панель управления, отклонить заявку Заказчика
                    на новую Услугу и/или отклонить заявку Заказчика на продление срока действия Услуги, а также ограничить пропускную способность
                    или лимиты Услуги, а также расторгнуть данное Соглашение в одностороннем порядке в следующих случаях:</p>
                <p>2.2.5.1. при отрицательном балансе или нехватке денежных средств на Счете Заказчика. В этом случае доступ к Услугам и/или
                    их функционирование приостанавливается до поступления следующего платежа, восстановления положительного значения баланса
                    лицевого счета Заказчика и последующей активации (продления) Услуг Заказчиком на выбранный период;</p>
                <p>2.2.5.2. при отсутствии оплаты за получение Сервисов и Опций, согласованных изменений и персональных настроек Услуги,
                    потребленных Заказчиком самостоятельно или предоставленных представителем Исполнителя в течение 4 (четырех) календарных
                    дней с момента получения таких Опций;</p>
                <p>2.2.5.3. в случае смерти или ликвидации Заказчика, а также обнаружения некорректных данных в регистрационной информации
                    Заказчика, отказа или невозможности последнего содействовать в приведении таких данных к корректному и актуальному состоянию.
                    При этом Исполнитель оставляет за собой право отказать в изменении таких данных, без объяснения причин на любом этапе оказания
                    Услуг и прекратить любые финансовые взаиморасчеты, в том числе возврат денежных средств;</p>
                <p>2.2.5.4. при подозрении в компрометации Учетной записи;</p>
                <p>2.2.5.5. при отказе или уклонении Заказчика от содействия в расследовании причин незапланированных перерывов в обслуживании,
                    нарушении требований безопасности, распространении персональных и других данных, полученных в процессе пользования Услугами
                    Исполнителя;</p>
                <p>2.2.5.6. В соответствии с требованиями Федерального Закона № 149 "Об информации, информационных технологиях и о защите
                    информации" (149-ФЗ), в том числе, но не ограничиваясь:</p>
                <p>- при обнаружении на ресурсах Заказчика контента, содержащего: детскую порнографию, мошеннические и/или фишинговые
                    действия, вредоносное ПО (в том числе хакерские программы и скрипты, вирусы и программы класса «spyware» и «трояны».), сцены
                    насилия, разжигание межнациональной розни, пропаганду ненависти и/или дискриминации людей по расовому, этническому,
                    половому, религиозному, социальному признакам, призвания к противоправной деятельности, рекламирование услуг, товаров, иных
                    материалов, распространение которых ограничено либо запрещено действующим законодательством Российской Федерации,
                    например, наркотических веществ и т. д.;</p>
                <p>- в случае обнаружения информации, выражающей в неприличной форме, которая оскорбляет человеческое достоинство
                    и общественную нравственность, явное неуважение к обществу, государству, официальным государственным символам Российской
                    Федерации, Конституции Российской Федерации или органам, осуществляющим государственную власть в Российской Федерации,
                    Генеральный прокурор Российской Федерации или его заместители обращаются в федеральный орган исполнительной власти,
                    осуществляющий функции по контролю и надзору в сфере средств массовой информации, массовых коммуникаций, информационных
                    технологий и связи;</p>
                <p>- в случае обнаружения объектов авторских и (или) смежных прав, которые распространяются без разрешения правообладателя или
                    иного законного основания;</p>
                <p>- В случае обнаружения информации, содержащей призывы к массовым беспорядкам, осуществлению экстремистской
                    деятельности, участию в массовых (публичных) мероприятиях, проводимых с нарушением установленного порядка, недостоверной
                    общественно значимой информации, распространяемой под видом достоверных сообщений, которая создает угрозу причинения вреда
                    жизни и (или) здоровью граждан, имуществу, угрозу массового нарушения общественного порядка и (или) общественной безопасности либо угрозу создания помех функционированию или прекращения функционирования объектов жизнеобеспечения, транспортной или
                    социальной инфраструктуры, кредитных организаций, объектов энергетики, промышленности или связи, информационных
                    материалов иностранной или международной неправительственной организации, деятельность которой признана нежелательной
                    на территории Российской Федерации, сведений, позволяющих получить доступ к указанным информации или материалам;</p>

                <p>2.2.5.7. при обнаружении скриптов или программного обеспечения, предназначенных для проведения спам-рассылок или других
                    массовых рассылок. При проведении таковых рассылок с ресурсов или IP-адресов, предоставленных Заказчику, в результате которых
                    почтовыми серверами получателей отвергаются более 10% отправляемых писем и/или поступают жалобы на назойливое
                    информирование путем отправки таких рассылок;</p>
                <p>2.2.5.8. при обнаружении передачи по сети бессмысленного или повторяющегося потока информации, создающего паразитную
                    нагрузку на передающие, принимающие узлы и приводящего к нарушению функционирования каких-либо узлов. К данному пункту
                    относятся, в частности, UDP-flood, DDOS-атаки;</p>
                <p>2.2.5.9. при размещении публичных (открытых) proxy, публичных VPN, открытых рекурсивных DNS resolver, других общедоступных
                    сервисов (в том числе с платным или приватным доступом), которые могут служить вспомогательными средствами для
                    противоправных действий в сети Интернет;</p>
                <p>2.2.5.10. при получении Исполнителем соответствующего предписания, содержащего требование о приостановлении оказания Услуг
                    Заказчику, со стороны государственного органа, регулирующего данные взаимоотношения и обладающего соответствующими
                    полномочиями в соответствии с действующим законодательством Российской Федерации;</p>
                <p>2.2.5.11. если использование Услуг Заказчиком может привести к причинению имущественного вреда Исполнителю или его
                    клиентам, а равно вызвать сбой технических и программных средств Исполнителя и третьих лиц;</p>
                <p>2.2.5.12. в случае нарушения Заказчиком условий данного Соглашения и других Условий и Правил;</p>
                <p>2.2.6. Приостанавливая Услуги Исполнитель не несет ответственности за извещение или не извещение любых третьих лиц о данном
                    инциденте и за возможные последствия, возникшие в результате такого предупреждения или его отсутствия.</p>
                <p>2.2.6.1. Приостановление оказания Услуг до выполнения Заказчиком требований Исполнителя по устранению нарушений
                    не отменяет выполнения Заказчиком всех своих обязательств по Соглашению.</p>
                <p>2.2.6.2. Время приостановления оказания Услуг по указанным в пункте 2.2.5. настоящего Соглашения причинам не считается
                    перерывом в оказании Услуг и не может рассматриваться как нарушение Исполнителем своих обязательств, предусмотренных
                    Соглашением.</p>
                <p>2.2.7. Изменять тарифы и Условия и Правила использования Услуг в одностороннем порядке с обязательным уведомлением
                    Заказчика не менее чем за 10 (десять) календарных дней до такого изменения. В случае несогласия с изменениями Заказчик обязуется
                    прекратить использование Услуг не позднее дня вступления таких изменений в силу. Использование Услуг Заказчиком после
                    вступления изменений в силу является неоспоримым фактом безусловного принятия Заказчиком всех внесенных изменений (акцепт
                    изменений).</p>
                <p>2.2.8. Привлекать для выполнения условий Соглашения, а также Условий использования отдельных Услуг и Сервисов сторонние
                    организации и оказывать предлагаемые ими услуги от своего имени или от имени таких организаций. При этом Исполнитель обязуется
                    требовать от них заявленного качества оказания услуг, не перекладывая ответственность за бесперебойность и качество таких услуг
                    и сервисов на себя.</p>
                <p>2.2.9. В целях проверки соблюдения требований безопасности производить сканирование публичных сервисов, расположенных
                    на ресурсах, принадлежащих или используемых Заказчиком, при помощи специализированного программного обеспечения, а также
                    применять иные доступные Исполнителю меры.</p>
                <p>2.2.10. Запрашивать, уточнять и документально фиксировать предоставленные Заказчику во временное пользование IP-адреса,
                    формируя «Акт предоставления во временное пользование IP-адресов». Отказ от формирования такого Акта со стороны Заказчика
                    может являться причиной для приостановки Услуг.</p>
                <p>2.2.11. В целях реализации мер, направленных на предотвращение инцидентов в области информационно-сетевой безопасности,
                    а также противодействия различного рода сетевым атакам, в том числе и распределенным атакам отказа от обслуживания (DDoS) для
                    публичных IP-адресов, Исполнитель вправе в любой момент без предварительного уведомления Заказчика ввести ограничения, в том
                    числе заблокировать порты или ограничить доступ к IP-адресу, ограничить полосу пропускания сервисов, которые могут
                    использоваться для атак класса UDP amplification или ограничить возможность использования данных сервисов.</p>
                <p>2.2.12. Удалить всю информацию, обрабатываемую Заказчиком на оборудовании Исполнителя и с использованием Услуг
                    Исполнителя, в случае прекращения Соглашения и/или в случае неоплаты Услуг.</p>
                <p>2.3. Заказчик обязан</p>
                <p>2.3.1. Выполнять Условия Соглашения, Условия использования основных Услуг и Условия использования отдельных Услуг
                    и Сервисов, а также Правила, размещенные на сайте Исполнителя, партнеров и поставщиков Услуг, на которые ссылается
                    Исполнитель.</p>
                <p>2.3.2. Следить за состоянием своего лицевого счета, на котором учитывается вся информация о потребляемых Услугах и платежах,
                    и своевременно производить оплату таких Услуг авансовым платежом в соответствии с действующими тарифами и стоимостью Услуг
                    и Сервисов.</p>
                <p>2.3.3. Читать и принимать к сведению информацию об изменениях в Соглашении и других регулирующих условия оказания Услуг
                    документах и правилах, публикуемую на веб-сервере Исполнителя. </p>
                <p>2.3.4. Сообщать о себе достоверную и полную информацию, позволяющую однозначно идентифицировать Заказчика, путем внесения
                    необходимой и достаточной информации в соответствующий раздел Панели управления (информация заполняется при регистрации
                    Заказчика на веб-сервере Исполнителя и в Личном кабинете).</p>
                <p>2.3.5. Информировать представителя Исполнителя о необходимости внесения изменений в регистрационную информацию,
                    подтверждая наличие изменений документально, в срок не позднее 7 (семи) дней с момента вступления изменений в силу. При
                    использовании недостоверных и/или неполных сведений, полученных от Заказчика, Исполнитель не несёт ответственности за любые
                    негативные последствия, вызванные действиями Заказчика на основании предоставленных недостоверных или неподлинных сведений,
                    или документов.</p>
                <p>2.3.6. Принимать меры по устранению уязвимостей, обнаруженных при проверке требований безопасности.</p>
                <p>2.3.7. Размещать на оборудовании Исполнителя исключительно лицензионное программное обеспечение и самостоятельно нести
                    ответственность за его размещение и использование.</p>
                <p>2.3.8. Самостоятельно осуществлять контроль за безопасностью, сохранностью информации, а также в случае необходимости
                    своевременно организовывать резервное копирование информации, размещаемой с использованием Услуг Исполнителя.</p>
                <p>2.3.9. Использовать Услуги Исполнителя исключительно легальным образом в целях, не противоречащих Федеральному
                    законодательству Российской Федерации. Не переносить на Исполнителя ответственность за ущерб любого рода, понесенный
                    Заказчиком или третьей Стороной в ходе незаконного использования Заказчиком Услуг Исполнителя.</p>
                <p>2.4. Заказчик имеет право</p>
                <p>2.4.1. Получить доступ ко всем необходимым Сервисам, предусмотренным Исполнителем для пользования Услугами.</p>
                <p>2.4.2. В соответствии с условиями настоящего Соглашения, Условиями и Правилами изменять количество, состав и технические
                    параметры оказываемых Исполнителем Услуг как автоматическим образом в рамках предоставленного программно-аппаратного
                    комплекса, так и путем обращения в соответствующие службы Исполнителя, посредством направления заявки через тикет-систему
                    Исполнителя в Панели управления.</p>
                <p>2.4.3. Получать техническую поддержку и консультации в соответствии с Условиями и Правилами.</p>
                <p>2.4.4. Использовать Сервисы и предложения, сформулированные Исполнителем, в качестве дополнительных Услуг и Опций,
                    указанных в Условиях использования отдельных услуг и сервисов.</p>
                <p>2.4.5. Обращаться к Исполнителю с просьбой изменить регистрационную информацию, обязуясь возместить последнему все затраты
                    на проведение расследования и анализ предоставленных данных согласно действующему на момент обращения Прайсу на оказание
                    данного вида Услуг.</p>
                <h2>3. СТОИМОСТЬ УСЛУГ И ПОРЯДОК РАСЧЕТОВ</h2>
                <p>3.1. Стоимость Услуг и Сервисов определяется тарифами и Условиями, опубликованными на сайте Исполнителя, или
                    Предложениями, направленными Исполнителем представителю Заказчика. Цены на все Услуги, Опции и Сервисы указаны в рублях
                    без учета НДС.</p>
                <p>3.3. Исполнитель учитывает финансовую информацию об оказываемых Услугах, а также платежах Заказчика на его лицевом
                    Счете/Счетах и обеспечивает Заказчику доступ к такой информации путём публикации её в Панели управления.</p>
                <p>3.4. Оплата Услуг производятся в рублях Российской Федерации (с учетом НДС) путем, перевода необходимой суммы денежных
                    средств на расчетный счет Исполнителя, через Способы оплаты, представленные в Панели управления и рекомендованные
                    Исполнителем.</p>
                <p>3.5. Платеж, осуществленный Заказчиком, в соответствии с законодательством Российской Федерации являющимся юридическим
                    лицом, через электронные платежные системы (в том числе терминалы быстрой оплаты), будет зачислен на соответствующий
                    Электронный лицевой счёт. Такой платёж не может быть закрыт Актом выполненных работ, следовательно, документы по такому
                    платежу Заказчику не предоставляются.</p>
                <p>3.6. Заказчик обязан самостоятельно отслеживать и в случае необходимости пополнять средства на своем лицевом счете. Если на день
                    расчетов на лицевом счете Заказчика недостаточно денежных средств для оплаты Услуг согласно выбранному тарифу, Исполнитель
                    имеет право приостановить предоставление Услуг. Возобновление предоставления Услуг производится после пополнения лицевого
                    счета Заказчика до необходимого уровня.</p>
                <p>3.7. Минимальный размер предоплаты за пользование Услугами и Сервисами зависит от конкретной услуги и опубликован на сайте
                    Исполнителя или в личном кабинете, при заказе или продлении такой Услуги или Сервиса.</p>
                <p>3.8. При оформлении платежных документов и/или платежа в адрес Исполнителя обязательна ссылка на Логин Заказчика,
                    в противном случае данный платеж может быть не идентифицирован и не зачислен на лицевой счет Заказчика.</p>
                <p>3.9. В случае осуществления оплаты Услуг лицом, не являющимся Заказчиком (оплата Услуг третьим лицом за Заказчика),
                    плательщик обязан в графе платежного поручения «Назначение платежа» указать за кого (за какого Заказчика) производится платеж.
                    В случае нарушения Заказчиком (плательщиком за Заказчика) условий настоящего пункта данный платёж может быть
                    не идентифицирован Исполнителем как платёж от имени этого юридического лица, в результате чего проведенный платеж будет
                    отнесен к неопознанным. На этом основании Исполнитель оставляет за собой право не отразить такой платёж на лицевом счете
                    Заказчика, в результате чего за Заказчиком образуется задолженность, которая может привести к временной приостановке Услуг.</p>
                <p>3.10. Исполнитель имеет право зачислять и списывать денежные средства, поступающие от Заказчика, вне зависимости
                    от назначения платежа, указанного в платежном документе, даже если это списание приведет к отрицательному значению баланса
                    лицевого счета, в следующих случаях: </p>
                <p>- для погашения задолженности по фактически потребленным Услугам;</p>
                <p>- при выполнении операций Автопродления Услуг в случае, когда данная настройка подключена Заказчиком или по его просьбе.</p>
                <p>3.11. Предоставленные Услуги подтверждаются Актом выполненных работ. Ознакомительные версии документов формируются
                    3 числа каждого месяца и доступны для ознакомления в Панели управления Заказчика.</p>
                <p>3.12. Если в течение 5 (пяти) рабочих дней со дня публикации ознакомительных версий Актов выполненных работ Заказчик
                    не предъявляет своих претензий в письменной форме, то Услуги, подтверждённые таким Актом, считаются принятыми Заказчиком.</p>
                <p>3.13. Оригинальные версии закрывающих документов (ровно, как и любых других документов, направляемых Исполнителем)
                    Заказчик может получить в офисе Исполнителя, посредством электронного документооборота с использованием продукта СБИС++
                    или посредством почтовой службы Российской Федерации, в том числе заказным письмом. При этом Исполнитель в качестве
                    основного способа предоставления документов определяет отправку посредством электронного документооборота. Остальные
                    способы предоставления являются платными и тарифицируются согласно Условиям и Правилам.</p>
                <p>3.14. В случае прекращения пользования Услугами Исполнитель осуществляет возврат неиспользованных денежных средств
                    Заказчика. Возврат выполняется на основании документального подтверждения произведенной оплаты (чек, квитанция, invoice)
                    и сверки расчетов. Возврат производится в течение 14 рабочих дней с момента получения всех необходимых для осуществления
                    возврата документов и при наличии письменного заявления (оригинала) с указанием полных реквизитов получателя.</p>
                <p>3.15. Остаток неиспользованных средств возвращается за вычетом сумм по оплате потребленных Услуг, комиссий и других издержек
                    Исполнителя на осуществления возврата.</p>
                <p>3.16. Перечисление возвращаемых денежных средств третьему лицу по просьбе Заказчика не производится.</p>
                <p>3.17. Возврат неиспользованных денежных средств невозможен в случае обнаружения причин приостановки Услуг,
                    соответствующих пункту 2.2.5. настоящего Соглашения, в случае нарушения требований, предусмотренных пунктами
                    2.3.4, 3.9. настоящего Соглашения.</p>
                <p>3.18. Услуги и тарифные планы, отсутствующие на сайте Исполнителя на дату обращения к ним, являются архивными Услугами.
                    Переход Услуги в статус «Архивный» осуществляется в момент прекращения возможности заказа такой Услуги на сайте и/или
                    в панели управления и удалении информации о стоимости и условиях приобретения Услуги с сайта Исполнителя. Регистрируясь
                    в системе Исполнителя, Заказчик подтверждает, что ознакомлен и согласен с действующими тарифами.</p>
                <p>3.19. Используя архивные Услуги, заказчик автоматически соглашается со следующими условиями:</p>
                <p>3.19.1. По инициативе Исполнителя консультации и поддержка по архивным Услугам может производиться на платных условиях
                    или не производиться вовсе.</p>
                <p>3.19.2. Архивная Услуга продолжает функционирование до тех пор, пока Заказчик не перейдет на актуальный тарифный план.
                    В случае прекращения действия архивной Услуги по инициативе Исполнителя, последний обязуется предоставить Заказчику
                    альтернативную Услугу, схожую по техническим характеристикам, и оказать помощь в настройках такой Услуги.</p>
                <p>3.19.3. Исполнитель поддерживает архивные Услуги в достаточном для функционирования техническом состоянии, что приводит
                    к дополнительным расходам и издержкам со стороны Исполнителя. В связи с вышеизложенным Исполнитель оставляет за собой право
                    изменять стоимость архивных Услуг на свое усмотрение, в любой момент времени, предварительно уведомив Заказчика согласно
                    условиям Соглашения.</p>
                <p>3.19.4. Технические характеристики и условия предоставления архивных Услуг, за исключением условий, описанных в пункте</p>
                <p>3.3.3. настоящего Соглашения, с момента перехода Услуги в статус «Архивный» не подлежат изменению и сохраняются как есть.
                    Любые изменения вносятся путем переговоров с представителем Исполнителя, при этом последний оставляет за собой право отказать
                    во внесении требуемых Заказчиком изменений, предложив альтернативную действующую Услугу.</p>
                <p>3.19.5. К архивным Услугам не применимы любые акции, бонусные и скидочные предложения, за исключением специальных
                    предложений, направленных Исполнителем в адрес Заказчика, использующего данную архивную Услугу.</p>
                <h2>4. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                <p>4.1. Исполнитель обеспечивает бесперебойное функционирование своего оборудования, задействованного в предоставлении Услуг,
                    за исключением случаев планового технического обслуживания, работ, вызванных сбоями в работе оборудования или программного
                    обеспечения, а также в случаях невозможности полноценного использования собственных ресурсов Исполнителя вследствие действий
                    или бездействия третьих лиц и/или неработоспособности транспортно-информационных каналов, а также в случае аварий или
                    обстоятельств непреодолимой силы. В случае внепланового отключения электроснабжения или аварии Исполнитель незамедлительно
                    приступает к устранению неполадок и по возможности оповещает Заказчика о внеплановом отключении.</p>
                <p>4.2. Исполнитель не несет ответственности за изменение свойств, функций и качества Услуг и Сервисов, предоставляемых Заказчику,
                    если таковые явно не описаны в Соглашении, Условиях и Правилах.</p>
                <p>4.3. Исполнитель не несет ответственности за случайные или намеренные действия Заказчика, которые могут привести к нарушению
                    каких-либо пунктов законодательства Российской Федерации и/или других стран.</p>
                <p>4.4. Исполнитель не несет ответственности за содержание информационных узлов, создаваемых и поддерживаемых Заказчиком или
                    пользователями его ресурсов. Наравне с тем Заказчик полностью ответственен за всю информацию, распространяемую или
                    размещаемую им каким-либо образом с помощью ресурсов Исполнителя.</p>
                <p>К данному пункту относится, но не ограничивается:</p>
                <p>- любая информация на сайте Заказчика, размещенная им самим или третьими лицами, которым Заказчик умышленно или
                    неумышленно предоставил доступ; </p>
                <p>- информация в электронных письмах, разосланных с помощью ресурсов Исполнителя.</p>
                <p>4.5. Действия, совершенные третьими лицами с помощью предоставленных Заказчику реквизитов доступа, считаются совершенными
                    Заказчиком. Ответственность за эти действия несет Заказчик. Это означает, что Заказчику следует с должным пониманием относиться
                    к защите выданных ему реквизитов доступа и избегать их компрометации.</p>
                <p>4.6. Исполнитель не несет ответственности за сбои, искажения и задержки в предоставлении Услуг, возникшие на стороне
                    поставщиков услуг, вызванные технологическими причинами объективного и субъективного характера.</p>
                <p>4.7. Исполнитель не несет юридической, материальной или иной ответственности:</p>
                <p>- за качество, содержание, соответствие действующему законодательству информации, полученной или переданной Заказчиком
                    посредством Услуг;</p>
                <p>- за использование Заказчиком услуг и сервисов других организаций, к которым он получил доступ посредством Услуг Исполнителя;</p>
                <p>- за качество работы транспортно-информационных каналов, предоставляемых третьими лицами (сторонними организациями).</p>
                <p>4.8. В случае возникновения перерыва в оказании Услуг по вине Исполнителя, Заказчик имеет право потребовать возврата
                    уплаченного аванса за пользование Услугой за период, когда отсутствовала возможность пользования данной Услугой. Возврат
                    производится из расчета 1/720 месячной стоимости услуги (среднее кол-во часов в месяце) за каждый полный/неполный час перерыва
                    оказания Услуги. Исполнитель и Заказчик вправе произвести перерасчет платы путем увеличения срока оказания услуги на срок
                    перерыва оказания услуги либо больший срок по усмотрению Исполнителя. Действие данного пункта распространяется на все виды
                    Услуг и сервисов, за исключением тех, к которым применяется Гарантированный уровень сервиса (SLA).</p>
                <p>4.9. Стороны пришли к согласию, что ответственность Исполнителя перед Заказчиком за период неоказания Услуги по любым
                    обстоятельствам ограничивается исключительно компенсацией и перерасчетом, оговоренным в п. 4.8. настоящего Соглашения. Иные
                    имущественные требования, такие как неполученная прибыль, упущенная выгода, а также любые прямые и косвенные убытки,
                    понесенные Заказчиком в период использования или неиспользования Услуг Исполнителя, не возмещаются и не подлежат
                    обсуждению.</p>
                <p>4.10. За все иные деяния и нарушения, не оговоренные в настоящей главе, Стороны несут ответственность в рамках действующего
                    законодательства Российской Федерации.</p>
                <p>4.11. При невыполнении одной из Сторон какого-либо положения настоящего Соглашения спорные вопросы регулируются
                    на основе взаимных соглашений, договоренностей и действующего законодательства Российской Федерации.</p>
                <h2>5. СРОК ДЕЙСТВИЯ, ПОРЯДОК ИЗМЕНЕНИЯ И ПРЕКРАЩЕНИЯ СОГЛАШЕНИЯ</h2>
                <p>5.1. Соглашение вступает в силу с момента регистрации Заказчика в учетной системе Исполнителя и/или поступления на счет
                    Исполнителя первого авансового платежа от Заказчика, направленного на оплату Услуг и Сервисов. Соглашение подразумевает
                    автоматическую пролонгацию на каждый последующий календарный год до тех пор, пока одна из Сторон не уведомит другую Сторону
                    о своем решении не продлевать срок действия настоящего Соглашения не менее чем за 10 (десять) дней до последнего дня текущего
                    срока действия Соглашения.</p>
                <p>5.2. Настоящее Соглашение остается в силе в случае изменения реквизитов Сторон, изменения их учредительных документов,
                    включая, но не ограничиваясь изменением собственника, организационно-правовой формы и другого. Исполнитель вправе передать
                    права и обязанности по исполнению Соглашения другому юридическому лицу или Индивидуальному предпринимателю. В случае
                    изменения реквизитов Стороны обязаны в 10-дневный срок уведомить об этом друг друга.</p>
                <p>5.3. Условия настоящего Соглашения, Условия и правила использования услуг могут быть изменены Исполнителем в одностороннем
                    порядке с предварительным уведомлением Заказчика за 10 (десять) календарных дней в соответствии с пунктом 2.1.4. настоящего
                    Соглашения. Использование Услуг Заказчиком после установленного срока вступления изменений в силу является неоспоримым
                    фактом безусловного принятия Заказчиком всех внесенных изменений (акцепт изменений).</p>
                <p>5.4. Действие настоящего Соглашения может быть прекращено по инициативе любой из Сторон. Со стороны Заказчика
                    с предварительным уведомлением через тикет-систему, путем направления авторизованной заявки и скан-копии заявления
                    о намерении прекратить пользование Услугами и указанием даты отключения Услуг. Со стороны Исполнителя путем направления
                    электронного письма через тикет-систему Исполнителя на контактный адрес, указанный в регистрационной информации.</p>
                <p>5.5. Прекращение пользования Заказчиком Услугами Исполнителя приравнивается к прекращению действия данного соглашения.
                    При получении уведомления от Заказчика, в соответствии с пунктом 5.4. настоящего Соглашения, Исполнитель производит
                    отключение Услуг, удаляет заказы и данные, хранившиеся и распространяемые с использованием Услуг Исполнителя, после чего
                    осуществляет возврат неизрасходованных денежных средств на лицевой счет Заказчика. Возврат остатка неиспользованных средств
                    производится согласно пунктам 3.153.18. настоящего Соглашения.</p>
                <h2>6. КОНФИДЕНЦИАЛЬНОСТЬ</h2>
                <p>6.1. Стороны подтверждают, что информация, которой они обмениваются в рамках подготовки, а также после заключения настоящего
                    Соглашения, носит конфиденциальный характер, являясь ценной для Сторон и не подлежащей разглашению, поскольку составляет
                    служебную и/или коммерческую тайну, имеет действительную и потенциальную коммерческую ценность в силу ее неизвестности
                    третьим лицам, к ней нет свободного доступа на законном основании.</p>
                <p>6.2. Любая информация, передаваемая посредством тикет-системы или электронной почты, а также полученная при посещении
                    представителями Заказчика Дата-центра, носит конфиденциальный характер.</p>
                <p>6.3. В Дата-центре и на других объектах Исполнителя запрещается фото-видеосъемка без получения письменного согласия
                    Исполнителя. Посещение дата-центра возможно исключительно при заказе соответствующего пропуска и в присутствии
                    представителя Исполнителя. </p>
                <p>6.4. Заказчик дает свое согласие на раскрытие факта сотрудничества Заказчика с Исполнителем, на разглашение факта
                    об использовании Заказчиком определенных Услуг, а также предоставляет Исполнителю право размещать фирменное наименование,
                    коммерческое обозначение и товарные знаки Заказчика в публичных источниках и на сайте Исполнителя. Такое использование
                    не подразумевает под собой раскрытие иных подробностей сотрудничества и не является использованием прав на охраняемые объекты
                    интеллектуальной собственности по смыслу положений законодательства Российской Федерации об охране прав на объекты
                    интеллектуальной собственности.</p>
                <p>6.5. Заказчик соглашается с тем, что Исполнитель вправе использовать предоставленные Заказчиком регистрационные данные
                    в целях проведения информационных мероприятий, связанных с предоставлением Услуг и Сервисов. А также направлять
                    на предоставленный Заказчиком электронный почтовый адрес и (или) путем оповещения SMS-сообщениями на предоставленный
                    Заказчиком номер мобильного телефона, размещать в пространстве, ограниченном доступом Заказчика, рекламные и информационные
                    сообщения по своему усмотрению.</p>
                <p>6.6. Стороны обязаны принимать все разумные меры, необходимые и целесообразные для предотвращения несанкционированного
                    раскрытия конфиденциальной информации. При этом принимаемые меры должны быть не менее существенны, чем те, которые
                    Сторона принимает для сохранения своей собственной информации подобного рода.</p>
                <p>6.7. Исполнитель имеет право раскрывать сведения о Заказчике только в соответствии с законодательством Российской Федерации
                    и по требованию соответствующих служб и органов.</p>
                <p>6.8. В случае нарушения предусмотренного Соглашением режима конфиденциальности Исполнитель вправе отказать Заказчику
                    в дальнейшем оказании Услуг в одностороннем порядке, начиная с даты обнаружения подобного нарушения, а также потребовать
                    компенсации убытков, понесенных вследствие нарушения Заказчиком режима конфиденциальной информации.</p>
                <h2>7. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</h2>
                <p>7.1. Исполнитель и Заказчик освобождаются от ответственности за частичное или полное неисполнение обязательств
                    по Соглашению, если оно вызвано обстоятельствами непреодолимой силы, а именно: пожара, наводнения, землетрясения, войны или
                    других обстоятельств, находящихся вне разумного контроля Сторон, и если эти обстоятельства непосредственно повлияли
                    на исполнение Соглашения. При этом исполнение обязательств по Соглашению отодвигается соразмерно времени, в течение которого
                    действовали такие обстоятельства.</p>
                <p>7.2. Если эти обстоятельства будут действовать более трех месяцев, то любая из Сторон вправе расторгнуть настоящий Соглашение
                    в одностороннем порядке. </p>
                <h2>8. РЕКВИЗИТЫ ИСПОЛНИТЕЛЯ</h2>
                <p> ИСПОЛНИТЕЛЬ</p>
                <p>Название: ООО «СИТИТЕЛЕКОМ»</p>
                <p>Юридический адрес: 105118, Россия, г Москва, ул. Буракова, д. 27, корп. 3, этаж 1, Комната 36; Помещение I</p>
                <p>Фактический адрес: 115230, г. Москва, Электролитный проезд, д. 3, стр. 47</p>
                <p>Почтовый адрес: 115230, г. Москва, Электролитный проезд, д. 3, стр. 47</p>
                <p>Телефон/факс: +7 (495) 545-44-00; +7 (495) 545-44-00 доб. 7</p>
                <p>Наименование банка: Ационерное общество "АЛЬФА-БАНК", г. Москва</p>
                <p>Расч. счет: 40702810002200003444</p>
                <p>БИК: 044525593</p>
                <p>Корр. счет: 30101810200000000593 </p>

            </div>
        </div>
    )
}

export default Agreement1;