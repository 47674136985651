import React, { useState } from "react";
import "./PartnersSection5.scss";

import LinkArrowRightOut from "../../../../UI/LinkArrowRightOut/LinkArrowRightOut.jsx"

import cornerArrow from "../../../../images/cornerArrow.svg";
import { arrServices } from "../../../../data/partnersServices.js";

import { motion, AnimatePresence } from "framer-motion";

const opacityX30= {
    hidden: {
        y: -10,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        y: -10,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    }
}

const opacityX30_2= {
    hidden: {
        y: -10,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        y: 10,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    }
}

const PartnersSection5 = () => {
    const [openElement, SetOpenElement] = useState([]);

    const openLi = (el) => {
        let mm = openElement;
        mm[el] = !mm[el];
        SetOpenElement([...mm]);
    }

    return (
        <section className="partners5">
            <div className="partners5__page" id="payments">
                <h3>Расчёт вознаграждения</h3>
                <ul>
                    {arrServices.map((el, index) =>
                        <li
                            key={index}
                            onClick={() => openLi(index)}
                        >
                            <div className={`partners5__liopen ${openElement[index] ? "partners5__liopen_active" : ""}`}>
                                <span>{el.name}</span>
                                <img src={cornerArrow} alt="" />
                            </div>

                            <AnimatePresence exitBeforeEnter>
                                {openElement[index] &&
                                    <motion.div
                                        initial={{ height: 0 }}
                                        animate={{ height: 'auto', transition: { duration: .3, ease: 'linear', } }}
                                        exit={{  height: 0, transition: { duration: .3, ease: 'linear' } }}

                                        className={`partners5__liclose partners5__liclose_active`}
                                    >
                                        {
                                            el.conditions && <motion.span variants={opacityX30} initial={'hidden'} animate={'visible'} exit={'exit'} className="partners5__liclose_point">{el.conditions}</motion.span>
                                        }
                                        {
                                            el.conditions2 && <motion.span variants={opacityX30} initial={'hidden'} animate={'visible'} exit={'exit'} className="partners5__liclose_point">{el.conditions2}</motion.span>
                                        }
                                        {
                                            el.conditions3 && <motion.span variants={opacityX30} initial={'hidden'} animate={'visible'} exit={'exit'} className="partners5__liclose_point">{el.conditions3}</motion.span>
                                        }
                                        {
                                            el.prize && <motion.span variants={opacityX30} initial={'hidden'} animate={'visible'} exit={'exit'} className="partners5__liclose_endline">{el.prize}</motion.span>
                                        }
                                        <motion.div variants={opacityX30_2} initial={'hidden'} animate={'visible'} exit={'exit'} className="partners5__button">
                                            <LinkArrowRightOut link={el.link} alt=''>Подробнее</LinkArrowRightOut>
                                        </motion.div>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </li>
                    )}
                </ul>
            </div>
        </section>
    )
}

export default PartnersSection5;
