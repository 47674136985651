import React from "react";
import "./TeamSection2.scss";

import LineOpacityRight from "../../../../UI/LineOpacityRight/LineOpacityRight.jsx";
import LinkArrowRight from "../../../../UI/LinkArrowRight/LinkArrowRight";

const TeamSection2 = () => {
    return (
        <section
            id="nextpage"
            className="team2"
        >
            <div className="team2__page">
                <div className="team2__content1">
                    <div className="team2__heading">
                        <p>Более 250 специалистов</p>
                    </div>

                    <div className="team2__question">
                        <span >Хотите стать частью нашей команды?</span>
                        <div className="team2__link">
                            <LinkArrowRight link='/vacancy'>Вакансии</LinkArrowRight>
                        </div>
                    </div>
                </div>

                <div className="team2__content2">
                    <div className="team2__text">
                        <p>Бизнес строится на людях, на их профессионализме, инициативности и желании менять мир. Наша команда состоит из профессионалов, которые преданы своим идеям и целям. Мы знаем, что ценность каждого сотрудника определяется его вкладом в развитие компании.</p>
                    </div>
                    <div className="team2__line">
                        <LineOpacityRight />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection2;