import React from "react";
import "./VacancySection6.scss";

const VacancySection6 = () => {
    return (
        <section className="vacancy6" >
            <div className="vacancy6__page">
                <div className="vacancy6__text">
                    <p>«Мы ценим профессионализм, готовность к&nbsp;сложным задачам и высокий уровень ответственности»</p>
                </div>
            </div>
        </section>
    )
}

export default VacancySection6;