import React from "react";
import { Link } from "react-router-dom";
import "./HeaderPage.scss";

import logoFilancoWhite from "../../images/logoFilancoWhite.svg";

const arrLinks = [
        {
            link: 'company',
            text: 'Компания',
        },
        {
            link: 'projects',
            text: 'Проекты',
        },
        {
            link: 'partners',
            text: 'Партнерам',
        },
        {
            link: 'team',
            text: 'Команда',
        },
        {
            link: 'recommendations',
            text: 'Рекомендации',
        },
        {
            link: 'contacts',
            text: 'Контакты',
        },
    ]

const HeaderPage = ({links = true, activeLink = 'company' }) => {
    return (
        <header className="headerpage">

            <Link to="/">
                <div className={`headerpage__logo`}>
                    <img className={`headerpage__logo_white`} src={logoFilancoWhite} alt="Filanco" />
                </div>
            </Link>

            {
                links &&
                <div className={`headerpage__links`} >
                    {arrLinks.map(el =>
                        <Link
                            to={`/${el.link}`}
                            key={el.link}
                            className={el.link === activeLink ? 'headerpage__links_active' : 'headerpage__links_passive'}
                        >
                            {el.text}
                        </Link>)}
                </div>
            }

            <div className={`headerpage__burger`} ></div>

        </header>
    )
}

export default HeaderPage;