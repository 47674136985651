import React, {useEffect} from "react";
import { Helmet } from "react-helmet";

import "./Documents.scss";

import DocumentsSection1 from "./components/DocumentsSection1/DocumentsSection1";
import DocumentsSection2 from "./components/DocumentsSection2/DocumentsSection2";
import DocumentsSection3 from "./components/DocumentsSection3/DocumentsSection3";
import Footer from "../../components/Footer/Footer.jsx";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Documents = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="documents" id="startpage">
            <Helmet>
                <title>Документы</title>
                <meta name="description" content="Прозрачность отношений - залог успеха. Все условия сотрудничества подробно изложены в документах." />
            </Helmet>
            <Burger activeLink="" />
            <UpArrowPage />
            <DocumentsSection1 />
            <DocumentsSection2 />
            <DocumentsSection3 />
            <Footer />
        </div>
    )
}

export default Documents;
