import CITYTELECOM from "../images/projectsLogo/City.svg";
import DATAHOUSE from "../images/projectsLogo/DH.svg";
import CLOUDIX from "../images/projectsLogo/Cloud.svg";
import NetworkToOffice from "../images/projectsLogo/Set_ofis.svg";
import ONECAM from "../images/projectsLogo/Onecam.svg";
import HOSTER from "../images/projectsLogo/Hoster.svg";
import DOMENUS from "../images/projectsLogo/Domenus.svg";
import HOSTLINE from "../images/projectsLogo/hostline logo.svg";
import WIFI2BIZ from "../images/projectsLogo/Wifi2Biz.svg";
import LEADER from "../images/projectsLogo/Leader.svg";
import NETRON from "../images/projectsLogo/Netron.svg";
import UPTP from "../images/projectsLogo/UPTP.svg";
import Platforma from "../images/projectsLogo/Platforma.svg";

export const projects = [
  {
    name: 'CITYTELECOM',
    logo: CITYTELECOM,
    slogan: 'Качество бизнес-процессов напрямую зависит от скорости и стабильности интернета.',
    content: 'Citytelecom.ru — оператор связи, присутствующий на более чем 500+ объектах коммерческой недвижимости. Среди основных преимуществ: быстрое подключение, стабильное соединение, высокая скорость передачи данных, лояльная ценовая политика.',
    services: [
      'Скоростной доступ в интернет',

      'Организация СКС, ЛВС',
      'Цифровая телефония',
      'Построение сетей Wi-Fi',
      'Виртуальные АТС',
      'IP-телевидение',
      'Организация удаленного офиса',
      <>Автономные системы AS&nbsp;+&nbsp;PI</>,
    ],
    url: 'https://citytelecom.ru/'
  },
  {
    name: 'DATAHOUSE',
    logo: DATAHOUSE,
    slogan: 'Надёжность дата-центра определяется уровнем доверия крупных игроков.',
    content: 'DataHouse.ru — сеть дата-центров уровня Tier lll, оказывающая весь спектр услуг от аренды и размещения серверов до облачных решений и администрирования. Дата-центры расположены в Москве, Санкт-Петербурге и Екатеринбурге.',
    services: [
      'Аренда сервера',
      'Облако VMware',
      'Аренда стойки или её части',
      'Облачные серверы (SaaS, IaaS)',
      'Размещение сервера',
      'Защита от DDoS',
      'Администрирование',
      'GPU для ИИ и вычислений',
    ],
    url: 'https://datahouse.ru/'
  },
  {
    name: 'CLOUD-IX',
    logo: CLOUDIX,
    slogan: 'Максимальная связанность и высокая скорость обмена данными без дополнительных затрат.',
    content: 'Cloud-IX.net — распределённая сеть точек обмена интернет-трафиком (Internet Exchanges), объединяет в себе IP-сети России и Европы в единое целое, формируя оптимальные маршруты доставки трафика до конечного пользователя. Подключитесь и передавайте контент вне зависимости от географии присутствия.',
    services: [
      'Аренда каналов связи',
      'Регистрация LIR',
      'Подключение дата-центров',
      <>Реализация AS и&nbsp;PI&#8209;сетей</>,
      'Присоединение домашних сетей',
    ],
    url: 'https://cloud-ix.net/'
  },
  {
    name: 'СЕТЬ-В-ОФИС',
    logo: NetworkToOffice,
    slogan: 'Грамотное проектирование, аккуратный монтаж, гарантия сроков и качества.',
    content: 'Сеть-в-офис.рф — проектирование, строительство и монтаж ВОЛС, СКС, СКУД, СВН. Выполнение строительных, монтажных и аварийных работ ведётся на высоком уровне с применением современных технологий и профессионального оборудования.',
    services: [
      'Строитетьство ВОЛС',
      'Аварийные работы',
      'Монтаж СКС, ЛВС',
      'Видеонаблюдение и СКУД',
      'Аудит сетей',
      'Разработка проектной документации',
    ],
    url: 'https://xn-----elcks9aqgdy7f.xn--p1ai/'
  },
  {
    name: 'ONECAM',
    logo: ONECAM,
    slogan: 'Просмотр изображения в реальном времени. Контролируйте обстановку из любой точки мира.',
    content: 'Onecam.ru — сервис облачного видеонаблюдения с просмотром изображения в реальном времени с любого мобильного устройства и возможностью контролировать ситуацию на объекте из любой точки мира. Платформа ориентирована на простое и удобное подключение систем видеонаблюдения, вне зависимости от их типа и местонахождения.',
    services: [
      'Построение систем видеонаблюдения «под ключ»',
      'Интеграция сторонних СВН в облако'
    ],
    url: 'https://onecam.ru/'
  },
  {
    name: 'ЛИДЕР',
    logo: LEADER,
    slogan: 'Создаём условия и формируем новые точки роста для развития российского бизнеса, преумножая национальный интеллектуальный капитал.',
    content: 'ИТ-Технопарк Лидер.рф объединяет в себе современный бизнес-кластер, ориентированный на развитие российских ИТ-решений, и дата-центры, сертифицированные по классу Tier 3. В распоряжении резидентов и гостей Технопарка: офисные пространства, конференц-залы, коворкинги, лаборатории и просторный атриум, являющийся центром притяжения при проведении различных мероприятий.',
    services: [
      'Аренда офисов, коворкингов, лабораторий и переговорных',
      'Размещение оборудования в современном ЦОД Tier 3',
      'Льготы и субсидии резидентам',
      'Бизнес-инкубаторы и привлечение инвестиций',
      'Dev-зоны с возможностью привлечения ИТ-специалистов',
      'Межотраслевые мероприятия',
    ],
    url: 'https://xn--d1achl2a.xn--p1ai/'
  },
  {
    name: 'НЭТРОН',
    logo: NETRON,
    slogan: 'Мы помогаем нашим клиентам создавать и реализовывать конкурентоспособные проекты на базе самых современных технологий.',
    content: 'Торгово-Производственная компания НЭТРОН обеспечивает застройщиков и интеграторов цифровых решений широким ассортиментом собственной кабельной продукцией, материалами и оборудованием для монтажных и строительных работ. Сильными сторонами продукции Netron являются: российское производство, бесперебойность поставок, расширенное гарантийное обслуживание.',
    services: [
      'Поставки материалов для всех инженерных систем',
      'Аутсорсинг снабжения',
      'Ответственное хранение и доставка партиями на объект',
      'Подбор по техническим заданиям, сметам и спецификациям',
      'Погрузочно-разгрузочные работы непосредственно на объекте',
    ],
    url: 'https://netron.pro/'
  },
  {
    name: 'ЮПТП',
    logo: UPTP,
    slogan: 'Комплексные решения по организации систем безопасности и оповещения на объектах различной сложности под ключ.',
    content: 'ЮПТП — интегратор систем безопасности, оператор проводного вещания и систем оповещения, обеспечивающий на объекте весь цикл работ: проектирование, предоставление ТУ на подключение, согласование, СМР, ПНР. Основным преимуществом является работа в режиме единого окна и полное сопровождение в получении всей разрешительной документации.',
    services: [
      'Радиофикация объектов',
      'Создание внутриквартальных технологических сетей связи',
      'Организация локальных систем безопасности (ЛСБ)',
      'Организация систем оповещения и безопасности',
      'СМР и ПНР противопожарной автоматики (ПА)',
      'Внедрение систем оповещения ',
    ],
    url: 'http://ooouptp.ru'
  },
  {
    name: 'ПЛАТФОРМА',
    logo: Platforma,
    slogan: 'Внедрение IoT и сценарного управления на объекте обеспечивают гибкость эксплуатации и высокую эффективность управления ресурсами.',
    content: <>Платформа — это комплекс IoT-решений для застройщиков, владельцев коммерческой недвижимости, управляющих компаний и жильцов. Мы предлагаем:
      <p>- Интеграция систем диспетчеризации</p>
      <p>- Энергоменеджмент (АСКУЭ, АСКУВ, АСКУТ)</p>
      <p>- Противопожарная автоматика</p>
      <p>- Сопровождению слаботочных сетей</p>
      <p>- Умная квартира и умный дом под ключ'</p>
    </>,
    services: [
      'Умная квартира и умный дом',
      'Энергоменджмент ',
      'Диспетчеризация (АСУД, АСУЗ)',
      'Видеонаблюдение (СВН, СОТ)',
      'Противопожарная автоматика',
      'Сопровождение СКС, ЛВС',
      'CRM для УК и приложение жильца',
    ],
    url: 'https://platforma.ru/'
  },
  {
    name: 'HOSTER',
    logo: HOSTER,
    slogan: 'Лучший хостинг — это когда, невзирая на тариф, вы самый важный клиент.',
    content: 'Hoster.ru — надёжный хостинг для веб-ресурсов и онлайн-проектов. Выгодные тарифы, производительное оборудование и грамотная круглосуточная техническая поддержка вместе с удобной панелью управления открывают доступ ко множеству вспомогательных сервисов и решений.',
    services: [
      'Безлимитный SSD-хостинг',
      'Регистрация доменов',
      'Классические и облачные VPS',
      'SSL-сертификаты',
      'CMS и Битрикс-хостинг',
      'Защита от DDoS',
      'Лицензии 1С-Битрикс'
    ],
    url: 'https://hoster.ru/'
  },
  {
    name: 'DOMENUS',
    logo: DOMENUS,
    slogan: 'Простой и удобный подбор доменного имени по ключевым словам, тематикам и синонимам.',
    content: <>Domenus.ru — аккредитованный регистратор доменов в зонах .RU, .SU и .РФ. Уникальная логика подбора в
      350&nbsp;доменных зонах, низкие цены и бесплатные DNS. Партнёрская программа проекта позволяет веб-студиям и
      веб-мастерам пользоваться услугами проекта со значительными привилегиями.</>,
    services: [
      <>Помощь в подключении хостинг&#8209;провайдеров</>,
      'Оптовая продажа через API и веб-интерфейс',
      'Регистрация и поддержка доменов',
      'Сервис SEO-продвижение',
      'SSL-сертификаты '
    ],
    url: 'https://www.domenus.ru/'
  },
  {
    name: 'HOSTLINE',
    logo: HOSTLINE,
    slogan: 'Недорогой и простой ISP-хостинг, где все сделано для вашего комфорта.',
    content: 'Хостинг-провайдер Hostline представлен в сегменте недорого хостинга и позволяет получить оптимальные решения начинающим сайтостроителям. Основной концепцией проекта являются доступность услуг, простота настроек и быстрая техническая поддержка.',
    services: [
      // 'Защита от парсинга и скликивания',
      // 'Регистрация и продление доменов',
      // 'Виртуальный хостинг',
      // 'Защита от DDoS',
      // 'SSL-сертификаты',
      // 'VPS/VDS',
      'Безлимитный хостинг',
      'Регистрация доменов',
      'Производительные VPS',
      'Бесплатные SSL',
      'Защита от DDoS',
      'Круглосуточная поддержка',
    ],
    url: 'https://hostline.ru/'
  },
  {
    name: 'WIFI2BIZ',
    logo: WIFI2BIZ,
    slogan: 'Управляйте продажами и формируйте лояльную аудиторию на основании аналитики.',
    content: 'WiFi2BiZ — маркетинговый и аналитический инструмент, построенный на сервисе Wi-Fi-авторизации. Такое решение по сбору информации о посетителях с возможностью проведения СМС-рассылок и публикации программ лояльности будет полезно владельцам кафе, баров, ресторанов, парикмахерских, косметических салонов и другим организаторам Wi-Fi-зон с публичным доступом.',
    services: [
      'Wi-Fi авторизация',
      'Анкетирование посетителей',
      'Баннерная реклама',
      'Формирование смс-рассылок',
      'Статистика клиентского поведения',
    ],
    url: 'https://wifi2biz.ru/'
  },
]
