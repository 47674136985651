export const arrServices = [
    // {
    //     name: 'Подключение ОКН',
    //     conditions: '',
    //     conditions2: '',
    //     prize: 'Индивидуальный расчёт вознаграждения.',
    //     link: 'https://citytelecom.ru/#modal-claim-2',
    // },
    {
        name: 'Подключение Бизнес Центров',
        conditions: '',
        conditions2: '',
        prize: 'Индивидуальный расчёт вознаграждения.',
        link: 'https://citytelecom.ru/#modal-claim-2',
    },
    // {
    //     name: 'Подключение абонента',
    //     conditions: '20% от месячной абонентской платы.',
    //     conditions2: '',
    //     prize: 'Выплата производится единоразово после 60 дней использования услуг клиентом.',
    //     link: 'https://citytelecom.ru/#modal-claim-2',
    // },
    {
        name: 'Интернет и телефония',
        // conditions: '20% от месячной абонентской платы.',
        conditions2: '',
        // prize: 'Выплата производится единоразово после 60 дней использования услуг клиентом.',
        prize: 'Единоразовая выплата равная месячной абонентской плате.',
        link: 'https://citytelecom.ru/#modal-claim-2',
    },
    {
        name: 'Монтаж СКС',
        // conditions: 'От 50 до 100 портов — 15 000 ₽ единоразово.',
        // conditions2: 'От 100 портов — индивидуальный расчёт.',
        prize: 'Индивидуальный расчёт вознаграждения.',
        link: 'https://xn-----elcks9aqgdy7f.xn--p1ai/sks/',
    },
    {
        name: 'Видеонаблюдение',
        conditions: '',
        conditions2: '',
        prize: 'Индивидуальный расчёт вознаграждения, в зависимости от объекта и объёма работ.',
        link: 'https://xn-----elcks9aqgdy7f.xn--p1ai/cctv/',
    },
    {
        name: 'СКУД',
        conditions: '',
        conditions2: '',
        prize: 'Индивидуальный расчёт вознаграждения, в зависимости от объекта и объёма работ.',
        link: 'https://xn-----elcks9aqgdy7f.xn--p1ai/skud/',
    },
    // {
    //     name: 'Облако VMware',
    //     conditions: '20% от суммы ежемесячного платежа.',
    //     conditions2: '',
    //     prize: 'Выплата производится единоразово после 60 дней использования услуг клиентом.'
    // },
    {
        name: 'Облачные серверы',
        conditions: '',
        conditions2: '',
        prize: '15% с каждого платежа клиента партнёра.',
        link: 'https://datahouse.ru/cloud/partner/',
    },
    {
        name: 'Объектное хранилище',
        conditions: '',
        conditions2: '',
        prize: '15% с каждого платежа клиента партнёра.',
        link: 'https://datahouse.ru/s3-cloud-storage/',
    },
    {
        name: 'Аренда серверов',
        conditions: '10% с каждого платежа клиента партнёра — для готовых конфигураций.',
        conditions2: '5% с каждого платежа клиента партнёра — для индивидуальной конфигурации.',
        conditions3: '10% с каждого платежа клиента партнёра — аренда сервера с GPU.',
        prize: '',
        link: 'https://datahouse.ru/dedicated/',
    },
    {
        name: 'Размещение в ЦОД',
        conditions: '',
        conditions2: '',
        prize: '10% с каждого платежа клиента партнёра.',
        link: 'https://datahouse.ru/colocation/',
    },
    {
        name: 'Хостинг',
        conditions: '',
        conditions2: '',
        prize: 'До 35% с каждого платежа клиента партнёра.',
        link: 'https://hoster.ru/reselling-hostinga',
    },
    {
        name: 'Домены',
        conditions: '',
        conditions2: '',
        prize: 'До 7% с каждого платежа клиента партнёра.',
        link: 'https://hoster.ru/domains',
    },
    {
        name: 'Лицензии 1с-Битрикс',
        conditions: '',
        conditions2: '',
        prize: 'До 5% с каждого платежа клиента партнёра.',
        link: 'https://hoster.ru/1c-bitrix',
    },
]
