import React from "react";
import "./ProjectsSection3.scss";

import LinkArrowRight from "../../../../UI/LinkArrowRight/LinkArrowRight.jsx";

const ProjectsSection3 = () => {
    return (
        <section className="projects3" >
            <div className="projects3__page">
                <div className="projects3__text" >
                    <p>Основным критерием успешности является длительность сотрудничества. Клиенты и&nbsp;партнеры рекомендуют наши услуги на&nbsp;протяжении многих лет. С&nbsp;некоторыми мы&nbsp;стали друзьями и с удовольствием организуем встречи и&nbsp;совместные мероприятия. Мы&nbsp;очень ценим обратную связь и&nbsp;стремимся стать лучше, для того чтобы вам было комфортно работать с&nbsp;нами в&nbsp;долгосрочной перспективе.</p>
                </div>
                <div className="projects3__head">
                    <div className="projects3__heading" >
                        <h3>Более 20 000 клиентов и партнёров</h3>
                    </div>
                    <div className="projects3__link" >
                        <LinkArrowRight link='/recommendations' alt='recommendations'>Рекомендации</LinkArrowRight>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProjectsSection3;