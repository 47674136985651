import React from "react";
import "./CompanySection2.scss";

const CompanySection2 = () => {
    return (
        <section
            className="company2"
            id="nextpage"
        >
            <div className="company2__page">
                <div className="company2__content1">
                    <h2 >Миссия</h2>
                    <div className="company2__text1" >
                        <p>Мы создаём и интегрируем понятные цифровые решения, которые помогают владельцам бизнеса оптимизировать IT-затраты, автоматизировать операционную деятельность, стать мобильней и улучшить качество бизнес-процессов</p>
                    </div>
                </div>
                <div className="company2__content2">
                    <h3 >Цель</h3>
                    <div className="company2__text2">
                        <p>Стать лидером в предоставлении цифровых инструментов для бизнеса, обеспечивая гарантированное качество услуг, высокий уровень сервиса и комплексный подход.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CompanySection2;