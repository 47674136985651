import React, {useCallback, useEffect, useState} from "react";
import "./GallerySlider2.scss";

import closeButton from "./images/closeButton.svg";
import arrow from "./images/arrow.png";

import { motion, AnimatePresence } from "framer-motion";

const opacityX20 = {
    initial: {
        x: 300,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: -300,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },
}
const opacityX_20 = {
    initial: {
        x: -300,
        opacity: 0,
    },

    animate: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: 300,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },
}
const opacity = {
    initial: {
        // x: 300,
        opacity: 0,
    },

    animate: {
        // x: 0,
        opacity: 1,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: -300,
        opacity: 0,
        transition: {
            duration: 0.2,
            ease: 'easeOut',
        },
    },
}

const GallerySlider2 = ({ setGalleryVisible = () => { }, galleryPhoto = [], startImg = 0 }) => {
    const [wayG, setwayG] = useState('');
    const [numberPhoto, setNumberPhoto] = useState(startImg);

    useEffect(() => {
        let el = document.querySelector('body');
        el.style.overflow = 'hidden';

        return () => {
            let el = document.querySelector('body');
            el.style.overflow = 'visible';
        }
    }, [])

    const changePhoto = (event, way) => {
        event.stopPropagation()

        let nextPh = numberPhoto;

        if (way === 'right' && nextPh < galleryPhoto.length - 1) {
            nextPh++;
        }
        else if (way === 'right' && nextPh === galleryPhoto.length - 1) {
            nextPh = 0;
        }
        else if (way === 'left' && nextPh > 0) {
            nextPh--;
        }
        else if (way === 'left' && nextPh === 0) {
            nextPh = galleryPhoto.length - 1;
        }

        setwayG(way);

        setNumberPhoto(nextPh);
    }

    const stopPR = (event) => {
        event.stopPropagation();

        if (event.clientX > document.documentElement.clientWidth / 2) {
            changePhoto(event, 'right')
        }
        else {
            changePhoto(event, 'left')
        }
    }

    const closeWindow = useCallback((event) => {
        event.preventDefault();

        if(event.key === 'Escape'){
            setGalleryVisible(false);
        }
    }, [setGalleryVisible]);

    useEffect(() => {
        document.addEventListener('keydown', closeWindow);

        return () => {
            document.removeEventListener('keydown', closeWindow);
        };
    }, [closeWindow]);

    return (
        <div className="galleryslider2" onClick={() => setGalleryVisible(false)}>

            <div className="galleryslider2__imgwrapper" onClick={stopPR}  >
                <AnimatePresence exitBeforeEnter>
                    {galleryPhoto.map((element, index) =>
                        index === numberPhoto &&

                        <motion.img
                            initial={'initial'}
                            animate={'animate'}
                            exit={'exit'}
                            variants={wayG === 'left' ? opacityX_20 : wayG === 'right' ? opacityX20 : opacity}

                            key={index}
                            src={element} alt="teams"
                        />

                    )}
                </AnimatePresence>
            </div>

            <button className="galleryslider2__closebutton" onClick={() => setGalleryVisible(false)}>
                <img src={closeButton} alt="" />
            </button>

            <button className="galleryslider2__rightbutton" onClick={(event) => changePhoto(event, 'right')}>
                <img src={arrow} alt="" />
            </button>

            <button className="galleryslider2__leftbutton" onClick={(event) => changePhoto(event, 'left')}>
                <img src={arrow} alt="" />
            </button>

        </div>
    )
}

export default GallerySlider2
