import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import "./Team.scss";

import TeamSection1 from "./components/TeamSection1/TeamSection1";
import TeamSection2 from "./components/TeamSection2/TeamSection2";
// import TeamSection3 from "./components/TeamSection3/TeamSection3";
import Footer from "../../components/Footer/Footer.jsx";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Team = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="team" id="startpage">
            <Helmet>
                <title>Команда</title>
                <meta name="description" content="Наша команда построена на профессионализме и идеологии совершенствования. Индивидуальный подход, цель на результат и забота о клиентах и партнерах являются нашими основными ценностями." />
            </Helmet>
            <Burger activeLink="team" />
            <UpArrowPage />
            <TeamSection1 />
            <TeamSection2 />
            {/* <TeamSection3 /> */}
            <Footer />
        </div>
    )
}

export default Team;
