import React from "react";

import "./Control.scss";

const Control = ({ setPageNumber, pageNumber, pagesTotal }) => (
    <div className="control">
        <span className="control__line1"/>
        <div className="control__pointwrapper">
            {new Array(pagesTotal).fill(null).map((_, index) =>
                <div key={index} className={`control__point ${index === pageNumber ? 'active' : ''}`} onClick={() => setPageNumber(index)}/>
            )}
        </div>
        <span className="control__line2"/>
    </div>
);

export default Control;
