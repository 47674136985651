import React, {useCallback, useState} from "react";
import "./DocumentsSection3.scss";

import { documents } from "../../../../data/documents.js";

import arrowUp from "./images/arrowUp.svg";
import { useEffect } from "react";

const DocumentsSection3 = () => {
    const [companyDoc, setCompanyDoc] = useState(documents[0]);

    const [selectNDS, setSelectNDS] = useState('БЕЗ НДС');
    const [selectNDSView, setSelectNDSView] = useState(false);
    const [mouseOverNDS, setMouseOverNDS] = useState(false);

    const [selectCompany, setSelectCompany] = useState(documents[0].company);
    const [selectCompanyView, setSelectCompanyView] = useState(false);
    const [mouseOverCompany, setMouseOverCompany] = useState(false);

    const [hiddenBtn, setHiddenBtn] = useState(true);

    const changeCompany = (comp) => {
        let cc = documents.filter(el =>
            el.company === comp ? true : false
        )
        setCompanyDoc(cc[0]);
    };
    const changeCompanyFromUrl = useCallback(() => {
        let hash = window.location.hash.slice(1);
        let nds = hash.match(/[^;]*nds:([^;]*)/);
        let project = hash.match(/[^;]*docs-project:([^;]*)/);

        project = project ? decodeURIComponent(project[1]) : '';
        project = companyAliasSwitcher(project);
        nds = nds ? decodeURIComponent(nds[1]) : '';

        setSelectCompany(project);
        setCompanyDoc(documents[documents.findIndex(item => item.company === project)]);
        if (project === 'DATAHOUSE.RU') {
            setSelectNDS(nds === 'yes' ? 'С НДС' : 'БЕЗ НДС');
        }
    }, []);
    const companyAliasSwitcher = (project) => {
        switch(project) {
            case 'datahouse':
                return 'DATAHOUSE.RU'
            case 'hoster':
                return 'HOSTER.RU'
            case 'hts':
                return 'HTS.RU'
            case 'hostline':
                return 'HOSTLINE.RU'
            case 'domenus':
                return 'DOMENUS.RU'
            default:
                return 'DATAHOUSE.RU'
        }
    };

    //When init page
    useEffect(() => {
        changeCompanyFromUrl();
    }, [changeCompanyFromUrl]);
    //When change company
    useEffect(() => {
        if (companyDoc.company !== 'DATAHOUSE.RU') {
            setHiddenBtn(true);
        }
        else {
            setHiddenBtn(false);
        }
    }, [companyDoc]);

    const selectCustom = (event) => {
        setSelectNDS(event.target.textContent);
        setSelectNDSView(!selectNDSView)
    };

    const selectCustom2 = (event) => {
        setSelectCompany(event.target.textContent);
        setSelectCompanyView(!selectCompanyView);
        changeCompany(event.target.textContent)
    };

    return (
        <section className="documents3">
            <div className="documents3__page">
                <div className="documents3__gallery">

                    <div className="documents3__menu" >
                        <div className="documents3__heading" >
                            <h2>Документы</h2>
                        </div>

                        <div className="documents3__selectcustom2">
                            <div className="documents3__selectcustom2_target" onClick={() => setSelectCompanyView(!selectCompanyView)}>
                                {selectCompany}
                                <img src={arrowUp} alt="" />
                            </div>
                            <ul className={selectCompanyView ? "documents3__selectcustom2_active" : "documents3__selectcustom2_passive"} onMouseOver={() => setMouseOverCompany(true)} onMouseOut={() => setMouseOverCompany(false)}>
                                {
                                    documents.map((el, index) =>
                                        <li key={index} onClick={(event) => selectCustom2(event)} className={selectCompany === el.company ? !mouseOverCompany ? "documents3__selectcustom2_blue" : "" : ""}>
                                            {el.company}
                                        </li>
                                    )
                                }
                            </ul>
                        </div>

                        <div className={`documents3__selectcustom ${hiddenBtn ? "documents3__selectcustom_hidden" : ""}`}>
                            <div className="documents3__selectcustom_target" onClick={() => setSelectNDSView(!selectNDSView)}>
                                {selectNDS}
                                <img src={arrowUp} alt="" />
                            </div>
                            <ul className={selectNDSView ? "documents3__selectcustom_active" : "documents3__selectcustom_passive"} onMouseOver={() => setMouseOverNDS(true)} onMouseOut={() => setMouseOverNDS(false)}>
                                <li onClick={(event) => selectCustom(event)} className={selectNDS === 'С НДС' ? !mouseOverNDS ? "documents3__selectcustom_blue" : "" : ""}>С НДС</li>
                                <li onClick={(event) => selectCustom(event)} className={selectNDS === 'БЕЗ НДС' ? !mouseOverNDS ? "documents3__selectcustom_blue" : "" : ""}>БЕЗ НДС</li>
                            </ul>
                        </div>

                        <div className={`documents3__selectcustom__hidden ${hiddenBtn ? "documents3__selectcustom__hidden_hidden" : ""}`}>
                            <div className="documents3__selectcustom__hidden_target">
                                БЕЗ НДС
                                <img src={arrowUp} alt="" />
                            </div>
                        </div>

                    </div>

                    <div className="documents3__content" >
                        <div className="documents3__agreements">
                            <h3>Соглашения</h3>
                            <ul >
                                {companyDoc.agreements.map((el, index) =>
                                    selectNDS === "БЕЗ НДС" && el.nds === false ?
                                        <li key={index} >
                                            <a href={el.link} target="_blank" rel="noreferrer">{el.name}</a>
                                        </li>
                                        : selectNDS === "С НДС" && el.nds ?
                                            <li key={index} >
                                                <a href={el.link} target="_blank" rel="noreferrer">{el.name}</a>
                                            </li>
                                            : el.nds === undefined &&
                                            <li key={index} >
                                                <a href={el.link} target="_blank" rel="noreferrer">{el.name}</a>
                                            </li>
                                )}
                            </ul>
                        </div>
                        <div className="documents3__rules">
                            <h3>Политики и Правила</h3>
                            <ul>
                                {companyDoc.rules.map((el, index) =>
                                    <li key={index} >
                                        <a href={el.link} target="_blank" rel="noreferrer">{el.name}</a>
                                    </li>
                                )}
                            </ul>
                        </div>
                        {companyDoc.services && <div className="documents3__services">
                            <h3>Услуги</h3>
                            <ul >
                                {companyDoc.services.map((el, index) =>
                                    <li key={index} >
                                        <a href={el.link} target="_blank" rel="noreferrer">{el.name}</a>
                                    </li>
                                )}
                            </ul>
                        </div>}
                    </div>

                </div>
            </div>
        </section>
    );
}

export default DocumentsSection3;
