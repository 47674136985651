import React, { useState, useEffect } from "react";
import "./Burger.scss";

import { Link } from "react-router-dom";
import logoFilancoWhite from "../../images/logoFilancoWhite.svg";

const Burger = ({ activeLink = 'company' }) => {
    const [addCl, setAddCl] = useState(false);

    const arrLinks = [
        {
            link: 'company',
            text: 'Компания',
        },
        {
            link: 'projects',
            text: 'Проекты',
        },
        {
            link: 'partners',
            text: 'Партнерам',
        },
        {
            link: 'team',
            text: 'Команда',
        },
        {
            link: 'recommendations',
            text: 'Рекомендации',
        },
        {
            link: 'contacts',
            text: 'Контакты',
        },
    ]

    useEffect(() => {
        let el = document.querySelector('body');
         if (addCl) {
            el.style.overflow = 'hidden';
        }
        else{
            el.style.overflow = 'visible';
        }
    }, [addCl])

    return (
        <div className={`burger burger_active`} onClick={()=>setAddCl(!addCl)}>

            <div className={`burger__linksopen  ${addCl && 'burger__linksopen_active'}`}>

                <div className={`burger__logo`}>
                    <Link to="/">
                        <img className={`burger__logo_white`} src={logoFilancoWhite} alt="Filanco" />
                    </Link>
                </div>

                <div className="burger__linkswrapper">
                    {arrLinks.map(el =>
                        <Link
                            to={`/${el.link}`}
                            key={el.link}
                            className={el.link === activeLink ? 'burger__linkswrapper_active' : 'burger__linkswrapper_passive'}
                        >
                            {el.text}
                        </Link>)}
                </div>

            </div>

            <div className={`burger__lines ${addCl ? 'burger__lines_active' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
            </div>

        </div>
    )
}

export default Burger;