import photo1 from "../images/galleryVacancy/photo1.jpg";
import photo2 from "../images/galleryVacancy/photo2.jpg";
import photo3 from "../images/galleryVacancy/photo3.jpg";
import photo4 from "../images/galleryVacancy/photo4.jpg";
import photo5 from "../images/galleryVacancy/photo5.jpg";

import photo6 from "../images/galleryVacancy/photo6.jpg";
import photo7 from "../images/galleryVacancy/photo7.jpg";
import photo8 from "../images/galleryVacancy/photo8.jpg";
import photo9 from "../images/galleryVacancy/photo9.jpg";
import photo10 from "../images/galleryVacancy/photo10.jpg";

import photo11 from "../images/galleryVacancy/photo11.jpg";
import photo12 from "../images/galleryVacancy/photo12.jpg";
import photo13 from "../images/galleryVacancy/photo13.jpg";
import photo14 from "../images/galleryVacancy/photo14.jpg";
import photo15 from "../images/galleryVacancy/photo15.jpg";

export const galleryPhoto = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,

    photo6,
    photo7,
    photo8,
    photo9,
    photo10,

    photo11,
    photo12,
    photo13,
    photo14,
    photo15,
]