import React from "react";
import "./CompanySection6.scss";

import LinkArrowRight from "../../../../UI/LinkArrowRight/LinkArrowRight.jsx";

const CompanySection6 = () => {
    return (
        <section className="company6"  >
            <div className="company6__page">
                <div className="company6__text"  >
                    <p>Бизнес строится людьми, желающими изменить мир. Наша&nbsp;команда состоит из&nbsp;профессионалов, которые преданы своим идеям и целям. Мы&nbsp;ценим каждого сотрудника за&nbsp;его уникальный вклад в&nbsp;развитие компании.</p>
                </div>
                <div className="company6__header">
                    <div className="company6__heading">
                        <h3>Команда преданных делу профессионалов</h3>
                    </div>
                    <div className="company6__link" >
                        <LinkArrowRight link='/team' alt=''>Команда</LinkArrowRight>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CompanySection6;