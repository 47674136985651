import React from "react";
import "./PartnersSection9.scss";

import { partnersBrands } from "../../../../data/partnersBrands";

const PartnersSection9 = () => {
    return (
        <section className="partners9">
            <div className="partners9__page">
                <div className="partners9__gallery">
                    {partnersBrands.map((el, index) =>
                        <div key={index} className="partners9__brand">
                            <div key={index} className="partners9__brandwrapper">
                                <img src={el.srcActive} alt={el.alt} className="partners9__brand_active" />
                                <img src={el.srcPassive} alt={el.alt} className="partners9__brand_passive" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default PartnersSection9;