import React from "react";
import { Helmet } from "react-helmet";

import "./Privacy.scss";

import Privacy1 from "./components/Privacy1/Privacy1";
import Footer from "../../components/Footer/Footer.jsx";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Privacy = () => {
    return (
        <div className="privacy" id="startpage">
            <Helmet>
                <title>Политика в отношении защиты персональных данных</title>
                <meta name="description" content="Мы хотим, чтобы вы всегда чувствовали себя в безопасности, поэтому уделяем большое значение конфиденциальности и работе с данными." />
            </Helmet>
            <Burger activeLink="" />
            <UpArrowPage />
            <Privacy1 />
            <Footer />
        </div>
    )
}

export default Privacy;
