//страница Проекты, первый экран
import React from "react";
import "./ProjectsSection1.scss";

import FirstScreen from "../../../../components/FirstScreen/FirstScreen.jsx";

import { motion } from "framer-motion";

const opacityScale110 = {
    hidden: {
        // scale: 1.1,
        x: -30,
        opacity: 0,
    },

    visible: {
        // scale: 1,
        x: 0,
        opacity: 1,
        transition: {
            duration: 3,
            // delay: 0.2,
            ease: 'easeOut',
        },
    },
}

const ProjectsSection1 = () => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: true }}

            className="projects1"
        >
            <motion.div
                variants={opacityScale110}
                className="projects1__BG1"
            ></motion.div>

            <FirstScreen
                activeHeaderLink='projects'
                textDescription='проекты'
                textLine1='Уникальный комплекс'
                textLine2='услуг для бизнеса'
            />
        </motion.section>
    )
}

export default ProjectsSection1;