import React, { useEffect } from "react";
import "./Privacy1.scss";

const Privacy1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="privacy1">
            <div className="privacy1__page">
                <h1>ПОЛИТИКА В ОТНОШЕНИИ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>
                <p>г. Москва</p>
                <p>«15» марта 2022 г.</p>
                <p>Мы хотим, чтобы вы всегда чувствовали себя в безопасности, поэтому уделяем большое значение конфиденциальности и работе с данными.</p>
                <p>С целью предоставления качественных Услуг и Сервисов мы собираем, используем и охраняем данные, которые вы предоставляете при взаимодействии с нашим сайтом, мобильным приложением (далее «сайт») с любого устройства и при коммуникации с нами в любой форме. Использование наших сайтов, Услуг и Сервисов означает ваше согласие на обработку персональных данных в соответствии с данной Политикой.</p>
                <h2>Термины и определения</h2>
                <p>Персональные данные — любая информация, позволяющая однозначно идентифицировать физическое лицо — владельца персональных данных.</p>
                <p>Обработка персональных данных — осуществление любых действий или совокупности действий в отношении персональных данных, включая сбор, запись систематизацию, накопление, хранение, обновление и изменение, извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и уничтожение как с использованием, так и без использования систем автоматизированной обработки персональных данных.</p>
                <p>Пользователь — физическое лицо, которое посетило сайт, панель управления, оставило заявку или заполнило предложенную Исполнителем форму на сайте или в Панели управления, или на стороннем ресурсе, на котором была выполнена переадресация на сайт исполнителя.</p>
                <h2>1. Способы получения и тип данных</h2>
                <p>Данные, которые Пользователь предоставляет добровольно при заполнении информационных полей на нашем сайте, в том числе при заполнении контактной формы, при подписке на новостную рассылку, при регистрации на мероприятия, при регистрации с целью заключения договора, а равно акцепта оферты.</p>
                <p>Данные, предоставленные с целью получения доступа к Панели управления или в момент заказа и активации той или иной Услуги:</p>
                <ul>
                    <li>ФИО,</li>
                    <li>дата рождения,</li>
                    <li>серия, номер, место и дата выдачи паспорта,</li>
                    <li>адрес регистрации физического лица,</li>
                    <li>фактический адрес,</li>
                    <li>почтовый адрес,</li>
                    <li>адрес электронной почты,</li>
                    <li>контактный телефон.</li>
                </ul>
                <p>Технические данные, которые автоматически передаются устройством, с помощью которого Пользователь использует наш сайт,
                    в том числе технические характеристики устройства, IP-адрес, информация, сохраненная в файлах «cookies», которые были
                    отправлены на устройство Пользователя, информация о браузере, дата и время доступа к сайту, адреса запрашиваемых страниц
                    и иная подобная информация.</p>
                <p>Поисковые фразы, вводимые Пользователями в поисковых формах Яндекс и Google, отслеживаемый трафик, страна инициализации
                    запроса, браузер, время просмотра страницы и другие общедоступные параметры с помощью общедоступных источников
                    и программно-аппаратных комплексов.</p>
                <p>Персональные данные и другая информация, содержащаяся в сообщениях, которые направляет Пользователь.</p>
                <p>Персональные данные, которые предоставляет Посетитель сайта, откликаясь на наши вакансии.</p>
                <p>Данные, полученные в ходе проведения специальных опросов.</p>
                <p>Иные данные, которые мы получаем при обращении через любой канал обмена данных.</p>

                <h2>2. Файлы Cookies</h2>
                <p>Файлы cookie, передаваемые оборудованию Пользователя и оборудованием Пользователя нам, могут использоваться для
                    предоставления Пользователю персонализированных Сервисов, для последующего таргетирования рекламы, в аналитических
                    исследованиях, а также для улучшения качества Услуг и Сервисов.</p>
                <p>В своих действиях мы руководствуемся тем, что Пользователь осознает, что оборудование и программное обеспечение,
                    используемые им для посещения сайтов в сети Интернет, могут обладать функцией запрета и ограничений пула операций с файлами
                    cookie (для любых сайтов или для определенных сайтов), а также удаления ранее полученных файлов cookie. </p>

                <h2>3. Использование информации</h2>
                <p>Мы собираем и храним только ту персональную информацию, которая необходима для исполнения условий Пользовательского
                    соглашения, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации
                    в течение определённого срока.</p>
                <p>Информация необходима:</p>
                <ul>
                    <li>Для идентификации Пользователя в рамках договоров и соглашений о предоставлении услуг и сервисов;</li>
                    <li>Для предоставления Пользователю персонализированных услуг и сервисов;</li>
                    <li>Для коммуникации с Пользователем, в том числе направления уведомлений, запросов и информации, касающихся использования
                        сервисов, исполнения соглашений и договоров, а также обработки запросов и заявок от Пользователя;</li>
                    <li>Для улучшения качества сервисов, удобства их использования, разработки новых сервисов;</li>
                    <li>Для формирования рекламных и информационных кампаний с учётом общедоступной и публичной информации о группах Пользователей, без привязки персональных данных к конкретному Пользователю.</li>
                    <li>Для аналитики трафика, аналитики популярности услуг и сервисов, и других аналитических и статистических исследований
                        с целью наиболее качественного выполнения договорных отношений и соглашений между сторонами.</li>
                </ul>
                <h2>4. Актуальность и корректность информации</h2>
                <p>Мы не проверяем достоверность персональной информации, предоставляемой Пользователем, и не имеем возможности оценивать
                    его дееспособность. При оказании услуг мы исходим из того, что Пользователь предоставляет достоверную и достаточную
                    персональную информацию и поддерживает ее в актуальном состоянии самостоятельно. Однако если предоставленная
                    Пользователем информация с целью получения доступа к Услугам и Сервисам является откровенно ложной и вызывает подозрение,
                    мы вынуждены провести проверку путем запроса документов, подтверждающих указанные данные. Последствия предоставления
                    недостоверной или недостаточной информации определены в Пользовательском соглашении.</p>
                <h2>5. Передача информации</h2>
                <p>Мы используем описанные выше виды данных для адаптации своих услуг и сервисов потребностям Пользователя. Никакая
                    информация личного характера об отдельном Пользователе третьим лицам не передаётся, кроме случаев, предусмотренных
                    законодательством Российской Федерации. Однако в ряде случаев, когда предоставление персонализированной Услуги или Сервиса
                    сопряжено с предоставлением информации поставщику услуги, мы просим Пользователя заполнить дополнительные данные,
                    необходимые для оказания данной услуги. При этом Пользователь вправе отказаться от заполнения данных, что автоматически
                    ограничивает его в потреблении таких Услуг или Сервисов. При оказании данных услуг мы руководствуемся Федеральным
                    законодательством, однако принимаем в расчёт общедоступные регламенты и положения от поставщиков услуг.
                    Мы оставляем за собой право делиться любыми сводными и обобщенными данными с рекламодателями и партнерами, при этом
                    не предоставлять им информацию о конкретных Пользователях или его Услугах.</p>
                <h2>6. Прекращение обработки, изменение и удаление персональной информации.</h2>
                <p>Мы прекращаем обработку персональных данных:</p>
                <ul>
                    <li>при наступлении условий прекращения обработки персональных данных или по истечении установленных сроков;</li>
                    <li>по достижении целей их обработки либо в случае утраты необходимости в достижении этих целей;</li>
                    <li>по вашему требованию, если обрабатываемые персональные данные являются незаконно полученными или не являются
                        необходимыми для заявленной цели обработки;</li>
                    <li>в случае выявления неправомерной обработки персональных данных, если обеспечить правомерность обработки невозможно;</li>
                    <li>в случае ликвидации Компании.</li>
                </ul>
                <p>Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию или её часть,
                    воспользовавшись функцией редактирования персональных данных в Панели управления.</p>
                <p>Удаление персональной информации производится на основании обращения Пользователя, путем отправки электронного
                    верифицированного сообщения через тикет-систему. Однако право на изменение или удаление информации может быть ограничено
                    в соответствии с требованиями законодательства. Такие ограничения могут предусматривать обязанность сохранять измененную или
                    удаленную Пользователем информацию на срок, установленный законодательством, и передавать такую информацию в соответствии
                    с законодательно установленной процедурой государственному органу.</p>
                <h2>7. Меры по защите персональной информации Пользователя</h2>
                <p>Мы принимаем необходимые и достаточные организационные и технические меры для защиты персональной информации
                    Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения,
                    а также от иных неправомерных действий с информацией со стороны третьих лиц. </p>
                <p>В целях обеспечения адекватной защиты ваших персональных данных мы проводим оценку вреда, который может быть причинен
                    в случае нарушения безопасности ваших персональных данных, а также определяем актуальные угрозы безопасности ваших
                    персональных данных при их обработке в информационных системах персональных данных.</p>
                <p>В Компании приняты локальные акты по вопросам безопасности персональных данных. Сотрудники Компании, имеющие доступ
                    к персональным данным, ознакомлены с настоящей Политикой и локальными актами по вопросам безопасности персональных
                    данных.</p>
                <h2>8. Изменение Политики конфиденциальности</h2>
                <p>Мы имеем право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции
                    указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное
                    не предусмотрено новой редакцией Политики.</p>
                <p>9. Ссылки на сайты третьих лиц</p>
                <p>Мы не контролирует и не несем ответственность за обработку информации третьими лицами и их программно-аппаратным
                    комплексом, в том числе сайтами, на которые Пользователь может перейти по ссылкам, доступным на наших информационных
                    ресурсах</p>
            </div>
        </div>
    )
}

export default Privacy1;