import React, { useState } from "react";
import { motion } from "framer-motion";

import "./HomeSection2.scss";

const HomeSection2 = () => {
    const [bgEnterAnimation, setBgEnterAnimation] = useState(false);

    return (
        <section className="section section2">
            <div className="section2__content">
                <h2>
                    Комплексные IT-решения<br />для вашего бизнеса
                </h2>
                <p>
                    Группа компаний ФИЛАНКО — интегратор IT-решений для бизнеса. Основана 2&nbsp;сентября 1999&nbsp;года.
                </p>
                <p>
                    Перечень услуг охватывает все телеком-направления — от виртуального хостинга и услуг дата-центров до организации слаботочных сетей и доступа в Интернет.
                </p>
            </div>

            <div className="section2__BG">
                <motion.div
                    viewport={{ once: false, amount: .01 }}
                    onViewportEnter={() => setBgEnterAnimation(true)}
                    onViewportLeave={() => setBgEnterAnimation(false)}
                    className={`section2__BG1 ${bgEnterAnimation ? 'section2__BG1--enter' : ''}`}
                />
                <div className="section2__BG2"/>
            </div>

        </section>
    );
};

export default HomeSection2;
