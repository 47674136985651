//
import React from "react";
import "./RecommendationsSection1.scss";

import FirstScreen from "../../../../components/FirstScreen/FirstScreen.jsx";

import { motion } from "framer-motion";

const RecommendationsSection1 = () => {

    const opacityScale110 = {
        hidden: {
            // scale: 1.1,
            x: -30,
            opacity: 0,
        },

        visible: {
            // scale: 1,
            x: 0,
            opacity: 1,
            transition: {
                duration: 3,
                // delay: 0.2,
                ease: 'easeOut',
            },
        },
    }

    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: true }}

            className="recommendations1"
        >
            <motion.div
                variants={opacityScale110}
                className="recommendations1__BG1"
            ></motion.div>

            <FirstScreen
                activeHeaderLink='recommendations'
                textDescription='Рекомендации'
                textLine1='Благодарим за сотрудничество'
                textLine2='и вклад в развитие'
            />
        </motion.section>
    )
}

export default RecommendationsSection1;
