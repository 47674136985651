import React from "react";

import classes from "./UpArrow.module.scss";

const UpArrow = ({ onClick, isStartPage }) => (
    <div className={classes.uparrow} onClick={onClick}>
        <div className={`${classes.uparrow__angle} ${!isStartPage ? classes['uparrow__angle--up'] : ''}`} />
        <span className={classes.uparrow__text}>{isStartPage ? 'далее' : 'наверх'}</span>
    </div>
);

export default UpArrow;
