import React from "react";
import "./PartnersSection4.scss"

const PartnersSection4 = () => {
    return (
        <section className="partners4" >
            <div className="partners4__page">

                <div className="partners4__ul1">
                    <h3 >Как привлечь?</h3>
                    <span >Используйте партнерскую ссылку</span>
                    <ul >
                        <li>Информируйте через рассылки</li>
                        <li>Публикуйте обзоры, статьи и пресс-релизы</li>
                        <li>Включайте инвайт в баннеры</li>
                        <li>Привлекайте в переговорах, отправляя инвайт лично</li>
                        <li>Делитесь информацией на форумах, каналах и соцсетях</li>
                        <li>Разместите информацию на своем сайте</li>
                    </ul>
                </div>
                <div className="partners4__line"></div>
                <div className="partners4__ul1">
                    <h3 >Чем привлечь?</h3>
                    <span >Адаптируйте выгоды под клиентов</span>
                    <ul >
                        <li>Привлекательные цены и дополнительные скидки</li>
                        <li>Весь комплекс IT-услуг в режиме единого окна</li>
                        <li>Гарантии условий, качества и сроков</li>
                        <li>Высокие компетенции и доверие крупных игроков</li>
                        <li>Опыт и профессионализм технических служб</li>
                        <li>Индивидуальный подход к задачам клиента</li>
                    </ul>
                </div>

            </div>
        </section>
    )
}

export default PartnersSection4;