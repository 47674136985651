import cityTelecom from "../images/homeLogo/CityTelecom.svg";
import hoster from "../images/homeLogo/Hoster.svg";
import platforma from "../images/homeLogo/Platforma.svg";
import datahouse from "../images/homeLogo/DataHouse.svg";
import setoffice from "../images/homeLogo/Set-office.svg";
import cloudix from "../images/homeLogo/Cloud-IX.svg";
import wifi2biz from "../images/homeLogo/Wifi2Biz.svg";
import domenus from "../images/homeLogo/Domenus.svg";
import onecam from "../images/homeLogo/Onecam.svg";
import hostline from "../images/homeLogo/Hostline.svg";
import leaderRf from "../images/homeLogo/Leader.svg";
import netron from "../images/homeLogo/Netron.svg";
import YUPTP from "../images/homeLogo/YUPTP.svg";

export const arrHomeProjects = [
    {
        url: 'https://citytelecom.ru/',
        src: cityTelecom,
    },
    {
        url: 'https://hoster.ru/',
        src: hoster,
    },
    {
        url: 'https://datahouse.ru/',
        src: datahouse,
    },
    {
        url: 'https://platforma.ru/',
        src: platforma,
    },
    {
        url: 'https://xn-----elcks9aqgdy7f.xn--p1ai/',
        src: setoffice,
    },
    {
        url: 'https://cloud-ix.net/',
        src: cloudix,
    },
    {
        url: 'https://wifi2biz.ru/',
        src: wifi2biz,
    },
    {
        url: 'https://hostline.ru/',
        src: hostline,
    },
    {
        url: 'https://xn--d1achl2a.xn--p1ai/',
        src: leaderRf,
    },
    {
        url: 'https://onecam.ru/',
        src: onecam,
    },
    {
        url: 'https://netron.pro/',
        src: netron,
    },
    {
        url: 'https://www.domenus.ru/',
        src: domenus,
    },
    {
        url: 'http://ooouptp.ru',
        src: YUPTP,
    },
]
