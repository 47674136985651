//ссылка на внутренний документ
import React from "react";
// import { Link } from "react-router-dom";
import styles from "./LinkArrowRight.module.scss";
import arrow from "./arrow.svg";
import { HashLink } from 'react-router-hash-link';

const LinkArrowRight = ({ link = '/', alt = '', children = 'text' }) => {
    return (
        <HashLink to={link} scroll={(el)=> el.scrollIntoView({behavior: 'smooth'})} className={styles.link}>
            <img src={arrow} alt={alt} />
            <span>{children}</span>
        </HashLink>
    )
}

export default LinkArrowRight;