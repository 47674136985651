//страница Компания, первый экран
import React from "react";
import "./CompanySection1.scss";

import FirstScreen from "../../../../components/FirstScreen/FirstScreen.jsx";

import { motion } from "framer-motion";

const opacityScale110 = {
    hidden: {
        // scale: 1.1,
        x: -30,
        opacity: 0,
    },

    visible: {
        // scale: 1,
        x: 0,
        opacity: 1,
        transition: {
            duration: 3,
            // delay: 0.2,
            ease: 'easeOut',
        },
    },
}

const CompanySection1 = () => {
    return (
        <motion.section
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.5, once: true }}

            className="company1"
        >
            <motion.div
                variants={opacityScale110}
                className="company1__BG1"
            ></motion.div>

            <FirstScreen
                activeHeaderLink='company'
                textDescription='о компании'
                textLine1='Надёжная IT&#8209;платформа'
                textLine2='для всех сегментов бизнеса'
            />

        </motion.section>
    )
}

export default CompanySection1;