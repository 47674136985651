import React from "react";
import "./PartnersSection3.scss";

import partners3BG1 from "../../../../images/partners3BG1.png";

import { motion } from "framer-motion";

const opacityX_30 = {
    hidden: {
        x: -50,
    },

    visible: {
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeOut',
        },
    },
}
const opacityX30 = {
    hidden: {
        x: 50,
    },

    visible: {
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeOut',
        },
    },
}

const PartnersSection3 = () => {
    return (
        <section className="partners3" >
            <div className="partners3__bg1">
                <img src={partners3BG1} alt="" />
            </div>
            <div className="partners3__page">
                <div className="partners3__numbers">

                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ amount: 0.9, once: true }}
                        variants={opacityX_30}
                        className="partners3__number1"
                    >
                        <span className="partners3__number1_big">350</span>
                        <div>
                            <span className="partners3__number1_little">средний доход в&nbsp;год</span>
                            <span className="partners3__number1_middle">тыс. ₽</span>
                        </div>
                    </motion.div>

                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ amount: 0.9, once: true }}
                        variants={opacityX30}
                        className="partners3__number23"
                    >

                        <div className="partners3__number2">
                            <div className="partners3__number2_pair">
                                <span className="partners3__number2_big partners3__number2_big_1">120</span>
                                <span className="partners3__number2_middle partners3__number2_middle_1">+</span>
                            </div>
                            <span className="partners3__number2_little">участников программы</span>
                        </div>

                        <div className="partners3__number2">
                            <div className="partners3__number2_pair">
                                <span className="partners3__number2_big">680</span>
                                <span className="partners3__number2_middle">+</span>
                            </div>
                            <span className="partners3__number2_little">приведенных клиентов в год</span>
                        </div>

                    </motion.div>

                </div>
            </div>
        </section>
    )
}

export default PartnersSection3;