import React from "react";
import "./FirstScreen.scss";

import HeaderPage from "../HeaderPage/HeaderPage.jsx";
import DescriptionPage from "../../UI/DescriptionPage/DescriptionPage.jsx";
import HeadingH1 from "../../UI/HeadingH1/HeadingH1.jsx";
import LineOpacityRight from "../../UI/LineOpacityRight/LineOpacityRight.jsx";

import { motion } from "framer-motion";

const opacityX20 = {
    hidden: {
        x: 20,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 1.1,
            // delay: 0.2,
            ease: 'easeOut',
        },
    },
}

const FirstScreen = ({
    activeHeaderLink = 'company',
    textDescription = 'textDescription',
    textLine1 = 'text1',
    textLine2 = 'text2'
}) => {
    return (
        <section className="firstscreen" >

            <div className="firstscreen__header" >
                <HeaderPage activeLink={activeHeaderLink} />
            </div>

            <div className="firstscreen__description" >
                <DescriptionPage text={textDescription} />
            </div>

            <div className="firstscreen__line">
                <LineOpacityRight />
            </div>

            <div className="firstscreen__page">
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={opacityX20}
                    className="firstscreen__heading"
                >
                    <HeadingH1
                        textLine1={textLine1}
                        textLine2={textLine2}
                    />
                </motion.div>
            </div>

        </section>
    )
}

export default FirstScreen;