import React, { useState } from "react";
import "./ContactsSection4.scss";

import { Link } from "react-router-dom";
import arrow from "../../../../images/arrow.svg";

import ModalInfo from "../../../../components/ModalInfo/ModalInfo";

import NumberFormat from 'react-number-format';

import axios from "axios";


// import ReCAPTCHA from "react-google-recaptcha";

const RESPONSE_ERROR_MESSAGE = 'Возникла ошибка, повторите отправку через некоторое время.';

const ContactsSection4 = () => {

    // function onChange(value) {
    //     console.log("Captcha value:", value);
    // }

    const [contactsForm, setContactsForm] = useState({
        name: '',
        email: '',
        phone: '',
        topic: '',
        message: ''
    });

    const [colorForm, setColorForm] = useState({});
    const [modal, setModal] = useState(false);
    const [responseError, setResponseError] = useState('');

    const validateForm = async (event) => {
        event.preventDefault();

        setResponseError('');

        let flagValidateForm = true;
        let fAction = {};

        if (contactsForm.name === '') {
            fAction = { ...fAction, name: true };
            flagValidateForm = false;
        }
        if (contactsForm.email === '' || !contactsForm.email.includes('@') || !contactsForm.email.includes('.')) {
            fAction = { ...fAction, email: true };
            flagValidateForm = false;
        }

        if (contactsForm.phone === '' || contactsForm.phone.length < 6) {
            fAction = { ...fAction, phone: true };
            flagValidateForm = false;
        }

        if (contactsForm.topic === '') {
            fAction = { ...fAction, topic: true };
            flagValidateForm = false;
        }
        if (contactsForm.message === '') {
            fAction = { ...fAction, message: true };
            flagValidateForm = false;
        }

        setColorForm(fAction);
        if (flagValidateForm === false) {
            console.log('валидация не пройдена');
            return;
        }

        try {
            const response = await axios.post('/sendmail.php', {
                name: contactsForm.name,
                email: contactsForm.email,
                phone: contactsForm.phone,
                topic: contactsForm.topic,
                message: contactsForm.message,
            }, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.data.send) {
                setContactsForm({
                    name: '',
                    email: '',
                    phone: '',
                    topic: '',
                    message: '',
                });
                setModal(true);
            } else {
                setResponseError(RESPONSE_ERROR_MESSAGE);
                console.warn(response.data.error);
            }
        } catch (e) {
            setResponseError(RESPONSE_ERROR_MESSAGE);
            console.warn(e);
        }
    }

    return (
        <section id="form" className="contacts4">
            <ModalInfo visible={modal} setVisible={setModal} img="" />

            <div className="contacts4__page">
                <div className="contacts4__formblock">
                    <div className="contacts4__heading">
                        <h2>Свяжитесь с нами</h2>
                    </div>

                    <div>
                        <div className="contacts4__content">
                            <p>По вопросам сотрудничества, участия в партнёрской программе и маркетинговым активностям просим вас обращаться через форму обратной связи</p>
                        </div>
                        <form className="contacts4__form" action="/">
                            <div className="contacts4__formplaceholder">
                                <input
                                    className={colorForm.name ? "contacts4__form_active" : "contacts4__form_passive"}
                                    placeholder=" "
                                    name="name"
                                    type="text"
                                    value={contactsForm.name}
                                    onChange={event => setContactsForm({ ...contactsForm, name: event.target.value })}
                                />
                                <label
                                    className={colorForm.name ? "contacts4__form_activetext" : "contacts4__form_passivetext"}
                                >Имя</label>
                            </div>

                            <div className="contacts4__form_lineblock1">
                                <div className="contacts4__formplaceholder">
                                    <input
                                        className={colorForm.email ? "contacts4__form_active" : "contacts4__form_passive"}
                                        placeholder=" "
                                        name="email"
                                        type="email"
                                        value={contactsForm.email}
                                        onChange={event => setContactsForm({ ...contactsForm, email: event.target.value })}
                                    />
                                    <label
                                        className={colorForm.email ? "contacts4__form_activetext" : "contacts4__form_passivetext"}
                                    >Email</label>
                                </div>
                                <div className="contacts4__formplaceholder">
                                    <NumberFormat
                                        format="+7 (###) ###-##-##" mask="_"

                                        className={colorForm.phone ? "contacts4__form_active" : "contacts4__form_passive"}
                                        placeholder=" "
                                        name="phone"
                                        type="tel"
                                        value={contactsForm.phone}
                                        onChange={event => setContactsForm({ ...contactsForm, phone: event.target.value })}
                                    />
                                    <label
                                        className={colorForm.phone ? "contacts4__form_activetext" : "contacts4__form_passivetext"}
                                    >Телефон</label>
                                </div>
                            </div>

                            <div className="contacts4__formplaceholder">
                                <input
                                    className={colorForm.topic ? "contacts4__form_active" : "contacts4__form_passive"}
                                    placeholder=" "
                                    name="topic"
                                    type="text"
                                    value={contactsForm.topic}
                                    onChange={event => setContactsForm({ ...contactsForm, topic: event.target.value })}
                                />
                                <label
                                    className={colorForm.topic ? "contacts4__form_activetext" : "contacts4__form_passivetext"}
                                >Тема</label>
                            </div>

                            <div className="contacts4__formplaceholder">
                                <input
                                    className={colorForm.message ? "contacts4__form_active" : "contacts4__form_passive"}
                                    placeholder=" "
                                    name="message"
                                    type="text"
                                    value={contactsForm.message}
                                    onChange={event => setContactsForm({ ...contactsForm, message: event.target.value })}
                                />
                                <label
                                    className={colorForm.message ? "contacts4__form_activetext" : "contacts4__form_passivetext"}
                                >Сообщение</label>
                            </div>

                            {!!responseError && (
                                <div className="contacts4__response-error">{responseError}</div>
                            )}

                            {/* <div className="contacts4__capcha">
                                <ReCAPTCHA
                                    sitekey="6LdLipkhAAAAAAGcjtv_htRUJWs_UwZisOh0GY7h"
                                    onChange={onChange}
                                />
                            </div> */}

                            <div className="contacts4__formsubmit">
                                <div className="contacts4__formprivacy">
                                    <p>Отправляя сообщение, я соглашаюсь на обработку персональных данных в соответствии с <Link to="/privacy" target="_blank">Политикой конфиденциальности</Link></p>
                                </div>
                                <button
                                    type="submit"
                                    form="data"
                                    value="Submit"
                                    className="contacts4__button"
                                    onClick={validateForm}
                                >
                                    <img src={arrow} alt="arrow" />
                                    <span>Отправить</span>
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactsSection4;
