//
// import recommend1 from "../images/photosRecommends/recommend1.jpg"
import recommend1 from "../images/photosRecommends/diplomaticacademy.jpg";
import recommend2 from "../images/photosRecommends/ipya.jpg";
import recommend3 from "../images/photosRecommends/komkor.jpg";
import recommend4 from "../images/photosRecommends/komplattelekom.jpg";
import recommend5 from "../images/photosRecommends/marketplace.jpg";
import recommend6 from "../images/photosRecommends/mgts.jpg";

export const arrRecommendations = [
    {
        name: 'С. Алтунин',
        position: 'Первый Проректор',
        photo: recommend1,
    },
    {
        name: 'Ф. Б. Успенский',
        position: 'Директор',
        photo: recommend2,
    },
    {
        name: 'О. С. Беленко',
        position: 'Начальник отдела',
        photo: recommend3,
    },
    {
        name: 'Д. С. Одинцов',
        position: 'Генеральный директор',
        photo: recommend4,
    },
    {
        name: 'С. С. Кунин',
        position: 'Генеральный директор',
        photo: recommend5,
    },
    {
        name: 'В. В. Громов',
        position: 'Начальник отдела',
        photo: recommend6,
    },
]