import React from "react";
import "./DocumentsSection2.scss";

const DocumentsSection2 = () => {

    return (
        <section id="nextpage" className="documents2" >
            <div className="documents2__page">
                <div className="documents2__text" >
                    <p>Библиотека документов объединяет в себе юридические аспекты и правила пользования массовыми услугами и сервисами. Мы стремимся сделать наше сотрудничество прозрачным, чётко фиксируем гарантии (SLA) в приложениях и строго им следуем.</p>
                </div>
            </div>
        </section>
    )
}

export default DocumentsSection2;