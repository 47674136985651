import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Header.scss";

import logoFilancoWhite from "../../images/logoFilancoWhite.svg";
import logoFilancoBlue from "../../images/logoFilancoBlue.svg";

const Header = ({ whiteLogo = true, links = true }) => {
    const [addCl, setAddCl] = useState(false);  //бургер

    return (
        <header className="header">
            <div className="header__logo-wrap">
                <img className={`header__logo ${whiteLogo ? 'header__logo--visible' : ''}`} src={logoFilancoWhite} alt="Filanco" />
                <img className={`header__logo ${!whiteLogo ? 'header__logo--visible' : ''}`} src={logoFilancoBlue} alt="Filanco" />
            </div>

            <div className={`header__links ${links ? 'header__links--visible' : ''}`}>
                <Link to="/company">Компания</Link>
                <Link to="/projects">Проекты</Link>
                <Link to="/partners">Партнерам</Link>
                <Link to="/team">Команда</Link>
                <Link to="/recommendations">Рекомендации</Link>
                <Link to="/contacts">Контакты</Link>
            </div>

            <div className={`header__linksopen  ${addCl ? 'active' : ''}`}>
                <div className="header__linkslogo">
                    <img src={logoFilancoWhite} alt="Filanco" />
                </div>
                <div className="header__linkswrapper">
                    <Link to="/company">Компания</Link>
                    <Link to="/projects">Проекты</Link>
                    <Link to="/partners">Партнерам</Link>
                    <Link to="/team">Команда</Link>
                    <Link to="/recommendations">Рекомендации</Link>
                    <Link to="/contacts">Контакты</Link>
                </div>
            </div>

            <div className={`header__burger ${addCl ? 'active' : ''}`} onClick={() => setAddCl(!addCl)}>
                <span/>
                <span/>
                <span/>
            </div>
        </header>
    )
}

export default Header;
