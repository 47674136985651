import React from "react";
import { Helmet } from "react-helmet";

import "./Agreement.scss";

import Agreement1 from "./components/Agreement1/Agreement1";
import Footer from "../../components/Footer/Footer";

import Burger from "../../components/Burger/Burger";
import UpArrowPage from "../../UI/UpArrowPage/UpArrowPage.jsx";

const Agreement = () => {

    return (
        <div className="agreement" id="startpage">
            <Helmet>
                <title>Пользовательское соглашение</title>
                <meta name="description" content="" />
            </Helmet>
            <Burger activeLink="" />
            <UpArrowPage />
            <Agreement1 />
            <Footer />
        </div>
    )
}

export default Agreement;
