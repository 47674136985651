import React from "react";
import { motion } from "framer-motion";

import "./HomeSection4.scss";

import { arrHomeProjects } from "../../../../data/homeProjects";

const HomeSection4 = () => (
    <section className="section section4">
        <div className="section4__iconswrapper">
            {arrHomeProjects.map((el) =>
                <div key={el.src} className="section4__icon">
                    <motion.a
                        initial={{ scale: 1.001 }}
                        whileHover={{ scale: 1.06, transition: { duration: 0.3, ease: 'easeInOut' } }}
                        href={el.url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={el.src} alt="" />
                    </motion.a>
                </div>
            )}
        </div>
    </section>
);

export default HomeSection4;
