export const companyHistory = [
    {
        year: 'Сегодня',
        // heading: 'Комплексный подход к решению бизнес-задач',
        heading: 'Интегратор IT-решений',
        history: 'Группа компаний ФИЛАНКО прошла успешную трансформацию из оператора связи в интегратора услуг и сервисов. Развитие проектов привело к росту возможностей и формированию комплексного подхода. Предоставление услуг в режиме единого окна выгодно, поэтому с большинством клиентов мы работаем на постоянной основе. За это время выросла партнёрская сеть, появились сервисы и услуги для операторов связи, а штат сотрудников вырос втрое. Мы искренне верим, что находимся в середине пути и можем расти и развиваться вместе.',
    },
    {
        year: '2019',
        heading: 'VMware и облачная платформа DataCloud',
        history: 'Интерес со стороны клиентов ЦОД к распределённым хранилищам и облачным вычислениям продиктовал необходимость развёртывания кластеров VMware. Инвестиции в проект окупились в первый год за счёт привлечения нескольких крупных клиентов. Параллельно с этим стартовали работы по созданию собственной SaaS-платформы DataCloud. Основным отличием которой является направленность на конечные пользовательские решения, сегментированные на конкретные виды деятельности: работы с базами данных, объектное хранение данных, вычислительные функции и многое другое.',
    },
    {
        year: '2017',
        heading: 'Облачное видеонаблюдение — Onecam.ru',
        history: 'Запуск облачного видеонаблюдения — Onecam.ru. Распределённое хранение данных с круглосуточным доступом из любой точки мира и с любого устройства. Именно так звучит основной лозунг проекта. Проект Onecam логично дополняет услуги по монтажу систем видеонаблюдения, однако при этом является отдельным сервисом. Это позволяет подключить к облачному хранилищу любые видеокамеры как физическим, так и юридическим лицам.',
    },
    {
        year: '2016',
        heading: 'Маркетинговый инструмент — WiFi2BiZ.ru',
        history: 'Запуск проекта WiFi2BiZ.ru. Данное решение разработано в ответ на требования закона о связи, где строго регламентированы нормы и политики авторизации в публичных сетях Wi-Fi. В процессе создания инструмента верификации пользователей, проект вырос в полноценный маркетинговый инструмент. Благодаря WiFi2BiZ.ru владельцы ресторанов, гостиниц, клубов, фитнес-центров, медицинских центров могут взаимодействовать с посетителями, рекламировать услуги и собирать аналитические сведения. WiFi2BiZ.ru является неотъемлемым решением при подключении объектов коммерческой недвижимости.',
    },
    {
        year: '2015',
        heading: 'Релиз собственной ERP-системы Halk',
        history: 'Зарегистрирован товарный знак Halk. Это официальный релиз собственной ERP-системы, которая включает множество модулей для управления телеком-услугами. Несмотря на то, что программный комплекс проектировался и разрабатывался под требования ФИЛАНКО, он универсален для любой телеком-компании. В данный момент на рынке нет подобных решений, способных контролировать работу всех телеком-систем и уметь взаимодействовать с таким количеством различных направлений бизнеса.',
    },
    {
        year: '2012',
        heading: <>Распределённая сеть точек обмена трафиком Cloud&#8209;IX.net</>,
        history: 'Cloud-IX.net — распределённая сеть точек обмена интернет-трафиком (Internet Exchanges), объединяет в себе IP-сети России и Европы в единое целое, формируя оптимальные маршруты доставки трафика до конечного пользователя. В 2012 году были созданы прямые стыки с М9, Level и Telia, к проекту присоединились первые крупные операторы связи. Cloud-IX это успешный инструмент для агрегации трафика операторов, однако он не менее значим и для проектов ГК ФИЛАНКО. Cloud-IX обеспечивает связанность всех инфраструктур и гарантирует оптимальные каналы обмена трафиком в сети дата-центров.',
    },
    {
        year: '2011',
        heading: <>Трансформация строительной компании — Cеть&#8209;в&#8209;офис.рф</>,
        history: 'Развитие Citytelecom.ru привело к необходимости построения собственных волоконно-оптических линий связи. Так в результате трансформации собственной строительной компании появился самостоятельный проект Сеть-в-офис.рф. В задачи проекта входило развитие и обслуживание внутренних сетей, а также организация каналов связи и всей инфраструктуры слаботочных сетей для сторонних заказчиков. Сеть-в-офис.рф - это профессиональные проектировщики и монтажные бригады, готовые организовать: подключение объекта одним или несколькими вводами, выполнить проектирование и построение СКС/ЛВС, организовать и настроить системы видеонаблюдения и контроля доступа.',
    },
    {
        year: '2008',
        heading: 'Аккредитованный регистратор Domenus.ru',
        history: 'Аккредитованный в зонах .RU, .SU и .РФ регистратор Domenus.ru появился как логическое продолжение активно растущего проекта Hoster.ru. Он взял на себя все функции по регистрации и поддержке доменных имён. В первую очередь Domenus привлекателен своей партнёрской программой, интеллектуальным подбором доменов и возможностью регистрации доменов в более чем 350 доменных зонах.',
    },
    {
        year: '2006',
        heading: 'Фомирование сети дата-центров Datahouse.ru',
        history: 'Datahouse.ru — появился как собственный ЦОД для серверов хостинга. Однако благодаря высокому уровню связанности очень быстро приобрёл популярность среди представителей малого и среднего бизнеса. В период с 2010 по 2014 было открыто ещё несколько дата-центров. В 2018 году был приобретён дата-центр на Нагорной ёмкостью 475 стоек. Сегодня проект представлен сетью распределённых провайдеронезависимых ЦОД в Москве, Екатеринбурге, Санкт-Петербурге и Украине. Здесь размещают свои ресурсы популярные интернет-магазины, сетевые ретейлы, фитнес-клубы, игровые платформы — все, кому важны надёжность и высокая скорость передачи данных.',
    },
    {
        year: '2001',
        heading: 'Расширение сети и оператор CityTelcom.ru',
        history: 'Создан оператор связи CityTelecom.ru. Рост числа абонентов привёл к расширению штата сотрудников. Подключения производились уже в плановом режиме, появились первые крупные клиенты. Утверждена стратегия развития сети. Сегодня CityTelecom присутствует на более чем 500 объектах коммерческой недвижимости. Помимо широкополосного доступа, проект предлагает абонентам услуги телефонии и виртуальные АТС, IP-телевидение, организацию Wi-Fi-зон. Пиринг с 540 операторами связи делает сеть CityTelecom.ru одной из самых обширных и успешных с точки зрения доставки трафика конечному потребителю.',
    },
    {
        year: '2000',
        heading: 'Хостинг-провайдер Hoster.ru',
        history: 'В 2000 году официально начал деятельность хостинг-провайдер Hoster.ru. История проекта насчитывает более миллиона зарегистрированных доменов, несколько сотен тысяч клиентов и несколько дата-центров. Услуги хостинга органично дополнены сервисами продвижения, антивирусами, защитой от DDoS-атак и другими полезными опциями. На сегодняшний день Hoster.ru по праву считается одним из старейших, надёжных, доступных и клиентоориентированных хостинг-провайдеров России.',
    },
    {
        year: '1999',
        heading: 'Вектор развития и первые клиенты',
        history: 'История ФИЛАНКО начинается с подключения небольшой группы пользователей к сети интернет. Вектор развития компании изначально был направлен на предоставление телематических услуг и услуг связи юридическим лицам. Первые подключения и организация небольшой сети послужили поводом для создания целого ряда проектов, призванных упростить и автоматизировать работу клиентов. С годами сеть росла и расширялась. Организация каналов связи и подключений стала профессиональной деятельностью. Появились собственные монтажные бригады, службы сервиса, проектировочный отдел, аварийные службы. Но самым важным событием этого года, стало формирование глобальной политики: «Комплексный подход, направленный на взаимовыгодное долгосрочное сотрудничество с каждым клиентом».',
    },
]