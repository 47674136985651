import React, { useState } from "react";
import "./GalleryFixPhoto.scss";

import { motion, AnimatePresence } from "framer-motion";

import GallerySlider2 from "../../components/GallerySlider2/GallerySlider2.jsx";

const opacityScale110Custom = {
    hidden: {
        scale: 1.05,
        opacity: 0,
    },

    visible: custom => ({
        scale: 1,
        opacity: 1,
        transition: {
            duration: 0.9,
            delay: 0.1 * custom,
            ease: 'easeOut',
        },
    }),
}
const opacity = {
    initial: {
        // x: 300,
        opacity: 0,
    },

    animate: {
        // x: 0,
        opacity: 1,
        transition: {
            duration: 0.3,
            ease: 'easeOut',
        },
    },

    exit: {
        // x: -300,
        opacity: 0,
        transition: {
            duration: 0.3,
            ease: 'easeOut',
        },
    },
}

const GalleryFixPhoto = ({ galleryPhoto = [] }) => {
    const [galleryVisible, setGalleryVisible] = useState(false);
    const [startImg, setStartImg] = useState(0);

    const startGallery = (numImg) => {
        setGalleryVisible(true);
        setStartImg(numImg);
    }

    return (
        <div className="galleryfix">

            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.3, once: true }}
                className="galleryfix__top"
            >
                <div className="galleryfix__top_left">
                    <motion.div
                        variants={opacityScale110Custom}
                        custom={0}
                        className="galleryfix__photo1"
                        onClick={() => startGallery(0)}
                    >
                        <img src={galleryPhoto[0]} alt="" />
                    </motion.div>
                </div>
                <div className="galleryfix__top_right">
                    <motion.div
                        variants={opacityScale110Custom}
                        custom={2}
                        className="galleryfix__photo2"
                        onClick={() => startGallery(1)}
                    >
                        <img src={galleryPhoto[1]} alt="" />
                    </motion.div>
                    <motion.div
                        variants={opacityScale110Custom}
                        custom={4}
                        className="galleryfix__photo3"
                        onClick={() => startGallery(2)}
                    >
                        <img src={galleryPhoto[2]} alt="" />
                    </motion.div>
                </div>
            </motion.div>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.2, once: true }}

                className="galleryfix__bottom"
            >
                <motion.div
                    variants={opacityScale110Custom}
                    custom={2}
                    className="galleryfix__photo4"
                    onClick={() => startGallery(3)}
                >
                    <img src={galleryPhoto[3]} alt="" />
                </motion.div>
                <motion.div
                    variants={opacityScale110Custom}
                    custom={4}
                    className="galleryfix__photo5"
                    onClick={() => startGallery(4)}
                >
                    <img src={galleryPhoto[4]} alt="" />
                </motion.div>
            </motion.div>
            <AnimatePresence exitBeforeEnter>
                {
                    galleryVisible &&
                    <motion.div
                        initial={'initial'}
                        animate={'animate'}
                        exit={'exit'}
                        variants={opacity}
                    >
                        <GallerySlider2 setGalleryVisible={setGalleryVisible} galleryPhoto={galleryPhoto} startImg={startImg} />
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

export default GalleryFixPhoto;