import React from "react";
import "./PartnersSection7.scss";

import partners7BG1 from "../../../../images/partners7BG1.png";

const PartnersSection7 = () => {
    return (
        <section className="partners7" >
            <div className="partners7__page">
                <h2 >Стратегические партнёры</h2>
                <div className="partners7__text" >
                    <p>Мы заинтересованы в развитии и открыты для программ кобрендинга и стратегического сотрудничества. Структура долгосрочных взаимовыгодных отношений предполагает синергию институтов бизнеса, рост финансовых показателей, расширение рынка, проникновение в новые ниши.</p>
                </div>
            </div>
            <div className="partners7__bg1">
                <img src={partners7BG1} alt="" />
            </div>
        </section>
    )
}

export default PartnersSection7;